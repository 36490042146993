import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import UIWrapper from "../../components/UIWrapper";

const ManifestLayoutView = (props) => {
  // const [clientOptions, setClientOptions] = useState([]);
  const [clientState, setClientSate] = useState("");
  const [projectState, setProjectState] = useState("");

  const [projectOptions, setProjectOptions] = useState([]);
  const [data, setDataLayout] = useState([]);

  const BASE_URL = process.env.MAIN_API;

  const fetchAllClients = async () => {
    const response = await axios.get(`${BASE_URL}/new-project/search/clients`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    });
    return response.data;
  };

  const {
    isLoading: isLoadingClients,
    error: errorClients,
    data: dataClients,
    isSuccess: isSuccessClients,
    status: statusClients,
  } = useQuery("all-clients", () => fetchAllClients());

  const fetchAllProjects = async () => {
    const response = await axios.get(
      `${BASE_URL}/new-project/search/projects?client=${clientState}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  useEffect(() => {
    clientState &&
      fetchAllProjects().then((data) => {
        setProjectOptions(data);
      });
  }, [clientState]);

  // const fetchManifestLayout = async () => {
  //   const response = await axios.get(
  //     `${BASE_URL}/manifest/fetch-manifest-format?client=${clientState}&project=${projectState}`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${(await Auth.currentSession())
  //           .getIdToken()
  //           .getJwtToken()}`,
  //       },
  //     }
  //   );
  //   return response.data;
  // };

  // useEffect(() => {
  //   projectState &&
  //     fetchManifestLayout().then((data) => {
  //       alert(data);
  //     });
  // }, [clientState]);

  // const {
  //   isLoading: isLoadingProjects,
  //   error: errorProjects,
  //   data: dataProjects,
  //   isSuccess: isSuccessProjects,
  //   status: statusProjects,
  // } = useQuery("all-projects", () => fetchAllProjects());

  // const {
  //   isLoading: isLoadingClients,
  //   error: errorClients,
  //   data: dataClients,
  //   isSuccess: isSuccessClients,
  //   status: statusClients,
  // } = useQuery(
  //   ["layout-view", debouncedFilterUsersService],
  //   () => fetchAllClients(debouncedFilterUsersService),
  //   {
  //     enabled: Boolean(debouncedFilterUsersService),
  //   }
  // );

  //   const {
  //   isLoading: isLoadingAllClients,
  //   error: errorAllClients,
  //   data: dataAllClients,
  //   isSuccess: isSuccessAllClients,
  //   status: statusAllClients,
  // } = useQuery("all-clients", () => fetchAllClients());

  // console.log(fetchAllProjects().then((data) => console.log(data)));

  // function createData(fields, alias, required) {
  //   return { fields, alias, required };
  // }

  // const keys = Object.keys(dataLayout);

  // const columns = [
  //   { field: "id", headerName: "Fields", width: 250 },

  //   {
  //     field: "alias",
  //     headerName: "Alias",
  //     width: 250,
  //     editable: true,
  //   },

  //   {
  //     field: "required",
  //     headerName: "Required",
  //     width: 250,
  //     editable: true,
  //     renderCell: (params) => {
  //       if (params.value === true) {
  //         return (
  //           <Button
  //             color="success"
  //             variant="outlined"
  //             sx={{
  //               borderRadius: 100,
  //               pointerEvents: "none",
  //               textTransform: "none",
  //             }}
  //           >
  //             <CheckIcon></CheckIcon> &nbsp; Required
  //           </Button>
  //         );
  //       }
  //       return (
  //         <Button
  //           color="info"
  //           variant="outlined"
  //           sx={{
  //             borderRadius: 100,
  //             pointerEvents: "none",
  //             textTransform: "none",
  //           }}
  //         >
  //           <InfoIcon></InfoIcon> &nbsp; Optional
  //         </Button>
  //       );
  //     },
  //   },
  // ];

  // function createRows(id, alias, required) {
  //   return {
  //     id,
  //     alias,
  //     required,
  //   };
  // }

  // const rows = keys.map((key) =>
  //   createRows(key, dataLayout[key].alias, dataLayout[key].required)
  // );

  // fetchAllProjects().then((data) => {
  //   setProjectOptions(data);
  // });

  const fetchManifestLayout = async () => {
    const response = await axios.get(
      `${BASE_URL}/manifest-layout/fetch-manifest-format?client=${clientState}&project=${projectState}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data;
  };

  useEffect(() => {
    projectState &&
      fetchManifestLayout().then((data) => {
        setDataLayout(data);
      });
  }, [projectState]);

  function createData(fields, alias, required) {
    return { fields, alias, required };
  }

  const keys = Object.keys(data);

  const columns = [
    { field: "id", headerName: "Fields", width: 250 },

    {
      field: "alias",
      headerName: "Alias",
      width: 250,
      editable: true,
    },

    {
      field: "required",
      headerName: "Required",
      width: 250,
      editable: true,
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <Button
              color="success"
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <CheckIcon></CheckIcon> &nbsp; Required
            </Button>
          );
        }
        return (
          <Button
            color="info"
            variant="outlined"
            sx={{
              borderRadius: 100,
              pointerEvents: "none",
              textTransform: "none",
            }}
          >
            <InfoIcon></InfoIcon> &nbsp; Optional
          </Button>
        );
      },
    },
  ];

  function createRows(id, alias, required) {
    return {
      id,
      alias,
      required,
    };
  }

  const rows = keys.map((key) =>
    createRows(key, data[key].alias, data[key].required)
  );

  return (
    <UIWrapper title="Project Layout" paper>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        mb={3}
      >
        {/* Select Clients */}
        <Grid item xs={12} sm={6} md={6} lg={4} sx={{marginTop: "5px"}}>
          <Autocomplete
            disablePortal
            options={dataClients}
            disabled={isLoadingClients}
            size="small"
            onChange={(event, value) =>
              value ? setClientSate(value) : setClientSate(event.target.value)
            }
            renderInput={(params) => <TextField {...params} label="Clients" />}
          />
        </Grid>

        {/* Select Projects */}
        <Grid item xs={12} sm={6} md={6} lg={4} sx={{marginTop: "5px"}}>
          <Autocomplete
            disablePortal
            options={projectOptions}
            disabled={!clientState}
            size="small"
            onChange={(event, value) => {
              setProjectState(value);
            }}
            renderInput={(params) => <TextField {...params} label="Projects" />}
          />
        </Grid>
      </Grid>

      <DataGrid
        style={{
          height: 600,
        }}
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        disableSelectionOnClick
      />
    </UIWrapper>
  );
};

export default ManifestLayoutView;
