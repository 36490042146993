import { useTheme } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Breadcrumbs, Link, NavigateNextIcon } from "../../mui";

const buildBreadCrumb = (path) => {
  const breadCrumbPaths = path.split("/");
  breadCrumbPaths.shift();
  let breadCrumbs = [];
  breadCrumbPaths.forEach((breadCrumb, index) => {
    breadCrumbs.push({
      path: breadCrumbPaths.slice(0, index + 1).join("/"),
      label: breadCrumb,
    });
  });
  return breadCrumbs;
};

const parsePath = (path) => {
  return path.replace(/\/(discover)\//, "");
};

const UIBreadcrumbs = (props) => {
  const location = useLocation();

  const history = useHistory();

  const theme = useTheme();

  const breadCrumbs = buildBreadCrumb(location.pathname);

  const handleBreadCrumbClick = (path) => {
    history.push(`/${path}`);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: "10px" }}>
      <Breadcrumbs
        maxItems={5}
        separator={
          <NavigateNextIcon
            sx={{ color: theme.palette.red.main }}
            fontSize="medium"
          />
        }
      >
        {breadCrumbs.map((breadCrumb) => (
          <Link
            underline="hover"
            key={breadCrumb.path}
            color={
              theme.palette.mode == "light"
                ? theme.palette.black.main
                : "#c9d1d9"
            }
            // color={theme.palette.mode == "light" ? "secondary" : "#c9d1d9"}
            sx={{
              fontSize: 18,
              cursor: "pointer",
              textTransform: "capitalize",
            }}
            onClick={() => handleBreadCrumbClick(breadCrumb.path)}
          >
            {breadCrumb.label}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default UIBreadcrumbs;
