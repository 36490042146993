import {
  Box, Checkbox, DeleteForeverIcon,
  DeleteOutlineOutlinedIcon,
  DragIndicatorTwoToneIcon,Typography,
  TextField,
  Select, MenuItem,
} from "../../mui";

import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { mapManifestActions } from "../../Store/map-manifest";

const Column = ({ list, col, index, mapping, drop }) => {
  const dispatch = useDispatch();
  return (
    <Draggable draggableId={col.id} index={index}>
      {(provided) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "rgb(211,211,211,0.3)",
            borderRadius: 1,
            mt: 1,
            mr: 2,
            ml: 2,
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DragIndicatorTwoToneIcon sx={{ flex: 1 }} />
          {/* Select / Unselect Checkbox */}
          <Checkbox
            sx={{ flex: 1 }}
            onChange={(event) =>
              dispatch(
                mapManifestActions.check({
                  column: col.name,
                  checked: event.target.checked,
                })
              )
            }
          />
          {/* Column Names */}
          <Typography sx={{ flex: 7 }}>{col.name}</Typography>

          {/* TextField of Raw headers */}
          <TextField
            sx={{ width: "225px" }}
            labelId="header-list-select-label"
            id="headerList"
            size="small"
            value={mapping[col.name]}
            onChange={(event) =>
              dispatch(
                mapManifestActions.map({
                  column: col.name,
                  new_name: event.target.value,
                })
              )
            }
          ></TextField>          

          {/* Delete Button as a Checkbox */}
          <Checkbox
            color="red"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
            icon={<DeleteOutlineOutlinedIcon />}
            checkedIcon={<DeleteForeverIcon />}
            defaultChecked={drop.includes(col.name)}
            onChange={(event) =>
              dispatch(
                mapManifestActions.drop({
                  column: col.name,
                  checked: event.target.checked,
                })
              )
            }
          />
        </Box>
      )}
    </Draggable>
  );
};

const ColumnList = React.memo(({ columns, mapping, drop}) => {
  return columns.map((col, index) => (
    <Column
      list={columns}
      col={col}
      index={index}
      key={col.id}
      mapping={mapping}
      drop={drop}
    />
  ));
});

const Columns = () => {
  const { order, mapping, drop } = useSelector((state) => state.mapManifest);
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    dispatch(
      mapManifestActions.shift({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box
            sx={{ paddingBottom: 2 }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <ColumnList columns={order} mapping={mapping} drop={drop}/>
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Columns.displayName = "Columns";
export default Columns;
