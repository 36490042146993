import { useEffect, useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "../mui";
import { Grid, useTheme } from "@mui/material";
import AcsService from "../services/AcsService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import AcsUniqueInvCode from "./AcsUniqueInvCode";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
var tempData = [];

const ServiceSendToLIMS = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [trackingNumberList, setTrackingNumberList] = useState([]);
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumberDetails, setTrackingNumberDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(10);

  const [showUnique, setShowUnique] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const tableBarCodeData = useRef(null);

  const tableRowData = [
    "Client",
    "Project",
    "Tracking Number",
    "Accessioning Count",
    "Accessioning Mainfest Count",
  ];

  const handleCheckDuplicates = () => {
    const req_data = tableData.map(({ client, project, tracking_number }) => {
      return { client, project, tracking_number };
    });
    setLoading(true);
    setShowUnique(true);
    AcsService.getTableBarCodeDataLims(req_data)
      .then((resp) => {
        setLoading(false);
        tableBarCodeData.current = resp.data;
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };

  const handleShowUniqueClose = () => {
    setShowUnique(false);
    tableBarCodeData.current = null;
  };

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  const submitData = async (updatedTableBarCodeData) => {
    setSubmitLoading(true);
    const uniqueBarcodeData = updatedTableBarCodeData.data?.reduce(
      (accum, curr) => {
        if (curr.inventory_code !== curr.old_inventory_code)
          accum[curr.old_inventory_code] = curr.inventory_code;

        return accum;
      },
      {}
    );

    const req_body = {
      tracking_details_data: tableData,
      unique_inventory_codes: uniqueBarcodeData,
    };
    await AcsService.saveLimsTrackingNumbers(req_body)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setShowUnique(false);
        clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    setSubmitLoading(false);
  };
  const clearForm = () => {
    props.setOpenServiceModal(false);
    setTableData([]);
    setTrackingNumberDetails(null);
    tempData = [];
  };

  const addToList = () => {
    let data = {};
    data = {
      client: trackingNumberDetails.client,
      project: trackingNumberDetails.project,
      tracking_number: trackingNumberDetails.tracking_number,
      accessioning_count: trackingNumberDetails.accessioning_count,
      accessioning_mainfest_count:
        trackingNumberDetails.accessioning_mainfest_count,
    };
    tempData.push(data);
    setTableData(tempData);
    setTrackingNumber("");
  };

  const handleClose = () => {
    props.setOpenServiceModal(false);
    setTableData([]);
    setTrackingNumberDetails(null);
    tempData = [];
  };

  useEffect(() => {
    AcsService.getTrackingNumbers(client, project)
      .then((resp) => {
        setTrackingNumberList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [client, project]);

  useEffect(() => {
    AcsService.getClientProjects(client, "")
      .then((resp) => {
        setProjectList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [client]);

  useEffect(() => {
    if (props.openServiceModal) {
      AcsService.getAllClients(client)
        .then((resp) => {
          setClientList(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.openServiceModal]);

  return (
    <Modal
      open={props.openServiceModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style}>
        <Modal
          open={showUnique}
          onClose={handleShowUniqueClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack>
            <AcsUniqueInvCode
              loading={loading}
              tableBarCodeData={tableBarCodeData.current}
              processing={submitLoading}
              hamdleParentSubmission={submitData}
              service_name="accession"
            />
          </Stack>
        </Modal>
        <Typography
          variant="h5"
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            marginBottom: "10px"
          }}
          color= {theme.palette.mode === "dark"? theme.palette.navItem.color : theme.palette.selectedNavItem.bgColor}
        >
          Send Samples To LIMS
        </Typography>
        <Divider style={{ width: "100%" }}></Divider>
        <Stack direction="row" style={{ width: "100%" }}>
          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={client || ""}
            onInputChange={(e, newValue) => setClient(newValue)}
            options={clientList}
            onChange={(e, newValue) => setClient(newValue)}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Client"}
                label={"Client"}
              />
            )}
          />
          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={project || ""}
            onInputChange={(e, newValue) => setProject(newValue)}
            disabled={client ? false : true}
            options={projectList}
            onChange={(e, newValue) => setProject(newValue)}
            getOptionLabel={(option) =>
              typeof option === "string" || option instanceof String
                ? option
                : ""
            }
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Project"}
                label={"Project"}
              />
            )}
          />

          <Autocomplete
            id="tags-standard"
            freeSolo
            inputValue={trackingNumber || ""}
            onInputChange={(e, newValue) => setTrackingNumber(newValue)}
            disabled={client && project ? false : true}
            options={trackingNumberList}
            onChange={(e, newValue) => {
              setTrackingNumberDetails(newValue);
            }}
            getOptionLabel={(option) => option.tracking_number}
            style={{ width: "30%", margin: "15px 5px 5px 5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                required={true}
                id="standard-multiline-flexible"
                size="small"
                type="text"
                name={"Tracking Number"}
                label={"Tracking Number"}
              />
            )}
          />
          <Button
            style={{ width: "10%", margin: "15px 5px 5px 5px" }}
            disabled={trackingNumber === "" ? true : false}
            type="submit"
            variant="outlined"
            onClick={addToList}
          >
            Add
          </Button>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Grid
            // container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {tableData && (
              <TableContainer style={{ marginTop: "30px" }}>
                <Table
                  stickyHeader={true}
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size={"small"}
                >
                  <TableHead sx={{ minWidth: 650 }}>
                    <TableRow sx={{ minWidth: 650 }}>
                      {tableRowData.map((row, index) => (
                        <TableCell
                          align="left"
                          key={index}
                          style={{ fontWeight: "bold" }}
                        >
                          {row}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: "none" }}>
                    {tableData !== 0 &&
                      tableData
                        .slice(
                          pageLarge * rowsPerPageLarge,
                          pageLarge * rowsPerPageLarge + rowsPerPageLarge
                        )
                        .map((row, index) => (
                          <TableRow
                            hover={true}
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">
                              {row.client || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.project || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.tracking_number || "None / Null"}
                            </TableCell>
                            <TableCell align="left">
                              {row.accessioning_count}
                            </TableCell>
                            <TableCell align="left">
                              {row.accessioning_mainfest_count}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPageLarge}
                  page={pageLarge}
                  onPageChange={handleChangePageLarge}
                  onRowsPerPageChange={handleChangeRowsPerPageLarge}
                />
              </TableContainer>
            )}
          </Grid>
        </Box>
        <Stack direction="row" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            style={{ width: "15%"}}
            disabled={tableData.length === 0 ? true : false}
            type="submit"
            variant="contained"
            onClick={handleCheckDuplicates}
          >
            Send To LIMS
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ServiceSendToLIMS;
