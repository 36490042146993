import {
  Paper,
  Stack,
  TextField,
  Typography,
  Grid,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { JsonForms } from "@jsonforms/react";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";

export default function AcsDynamic(props) {
  let handleChange = (i, data) => {
    if (data) {
      let newFormValues = props.formValuesSubject;
      newFormValues[i] = data.data;
      props.setFormValuesSubject(newFormValues);
    }
  };

  let handleSubjectCodeChange = (i, e) => {
    let newFormValues = JSON.parse(JSON.stringify(props.formValuesSubject));
    newFormValues[i].subject_code = e.target.value;
    props.setFormValuesSubject(newFormValues);
  };
  return (
    <Paper
      style={{
        display: props.activeStep === 1 ? "block" : "none",
        padding: "20px 0px",
        margin: "35px 0px",
        background: "#eaeaf0",
      }}
      elevation={0}
      variant="outlined"
    >
      <Stack
        direction="column"
        spacing={2}
        style={{ overflow: "auto" }}
        alignItems="center"
        justifyContent="center"
      >
        {props.formValuesSubject.map((option, index) => (
          <Paper
            style={{
              padding: "10px",
              width: "95%",
              paddingBottom: "25px",
            }}
            key={index}
            // variant="outlined"
            elevation={2}
          >
            <Stack direction="row" style={{ width: "100%" }}>
              <Stack direction="column" style={{ width: "100%" }}>
                {props.bulkScanFlag && (
                  <TextField
                    id="standard-multiline-flexible"
                    label={"Subject Code"}
                    type="text"
                    variant="outlined"
                    style={{
                      width: "100%",
                      margin: "0 0 10px 0",
                    }}
                    size="small"
                    // inputValue={option.subject_code || ""}
                    value={option.subject_code || ""}
                    onChange={(e) => handleSubjectCodeChange(index, e)}
                  />
                )}

                <JsonForms
                  schema={props.dynamicInputs.subject}
                  uischema={props.subjectSchema}
                  data={option}
                  renderers={materialRenderers}
                  cells={materialCells}
                  key={index}
                  onChange={(data) => handleChange(index, data)}
                  // onChange={({ errors, data }) =>
                  //   props.setDynamicValuesSubject(data)
                  // }
                />
              </Stack>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Paper>
  );
}
