import { Box, Grid } from "@mui/material";
import {
  Autocomplete,
  Button,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton
} from "../mui";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UIWrapper from "../components/UIWrapper";
import AcsService from "../services/AcsService";
import BiService from "../services/BiService";
import { snackbarActions } from "../Store/snackbar";
import { FormContainer } from "react-hook-form-mui";
import FileUploadBulkEdit from "../components/FileUploadBulkEdit";
import { useInterval } from "../components/helper/utils";
import RefreshIcon from "@mui/icons-material/Refresh";
// import CloseIcon from "@mui/icons-material/Close";
import LockResetIcon from "@mui/icons-material/LockReset";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SendIcon from '@mui/icons-material/Send';

const EditFileUpload = (props) => {
  const [value, setValue] = React.useState("one");
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientState, setClientState] = useState("");
  const [projectState, setProjectState] = useState("");
  const [files, setFiles] = useState(null);
  const [downloads, setSetDownloads] = React.useState([]);
  const [disabledButtons, setDisabledButtons] = useState([]);

  const dispatch = useDispatch();

  // Tabs change function.
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Download the sample for selected client and Project.
  const downloadTemplate = () => {
    AcsService.downloadTemplate(clientState, projectState)
      .then((resp) => {
        const url = resp.data.presigned_url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template");
        document.body.appendChild(link);
        link.click();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successfully Downloaded Template",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
        setFiles(null);
      });
    return;
  };

  // Submit for processing a file.
  const handleEditSubmission = (rowData, index) => {
    setDisabledButtons([...disabledButtons, index]);
    AcsService.uploadBulkEditFile(rowData.client, rowData.project, rowData.file_name)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setFiles(null);
      })

      .catch((err) => {
        setFiles(null);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Upload Submission Failed",
          })
        );
      });
    return;
  };

  // auto refresh to get the updated list.
  useInterval(() => {
    handleRefresh();
  }, 1000 * 8);

  // Get the updated list after refreshing.
  const handleRefresh = () => {
    BiService.getBulkUpdateFile()
      .then((resp) => {
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Download .txt file generic funtion for error and summary.
  const downloadTxtFile = (rowData) => {
    let details = [];
    const element = document.createElement("a");
    if (rowData.status === "ERROR" || rowData.status === "FAILED") {
      element.download = "error-detail.txt";
      details = [rowData.validation_error];
    } else {
      element.download = "summary-detail.txt";
      let tableString = rowData.update_summary.msg;

      if (rowData.update_summary.summary.length) {
        tableString = rowData.update_summary.msg + "\n\n" +
          rowData.update_summary.summary_msg + "\n\n" +
          "INVENTORY CODE\t\tCOLUMN NAME\n\n";

        rowData.update_summary.summary.forEach(item => {
          tableString += `${item.inventory_code}\t\t${item.column_name}\n`;
        });
      }

      rowData.update_summary.summary.forEach(item => {
        tableString += `${item.inventory_code}\t\t${item.column_name}\n`;
      });
      details = [tableString];
    }
    const file = new Blob(details, { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // Get the list of all projects after selecting Client.
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        BiService.searchProjects(clientState, projectState).then((resp) =>
          setProjectOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [projectState, clientState]);

  //  Get the list of all Clients.
  useEffect(() => {
    handleRefresh()
    BiService.getAllClients("").then((resp) => setClientOptions(resp.data));
    return;
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
    return `${new Date(dateString).toLocaleDateString('en', options)} ${hh} : ${mm} : ${ss}`
  }

  return (
    <UIWrapper title="Edit or Update Data" paper>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="file upload and Bulk update tabs"
        >
          <Tab value="one" label="File Upload" />
          <Tab value="two" label="Bulk Update" />
        </Tabs>
        <Divider style={{ width: "100%" }}></Divider>

        {/*  File Upload container */}
        {value === "one" && (
          <Grid
            container
            spacing={2}
            sx={{ mt: 1 }}
            align="center"
            justify="center"
          >
            {/* Instruction Section */}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography style={{ textAlign: "left" }}>
                Upload a file with the following columns:{" "}
                <b>inventory_code, col_name, new_value,</b> and <b>reason</b>.
                <br />
                Column Name (col_name) is the SampledSphere name of the data
                attribute that you are targeting (e.g. number_of_cells).
                <br />
                New Value (new_value) is the new value that you are replacing /
                updating in the column name.
                <br />
                <br />
              </Typography>
            </Grid>
            {/* Select client and Project Section */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormContainer>
                <Typography
                  color="rgba(0, 0, 0, 0.6)"
                  style={{ marginBottom: "5px" }}
                >
                  Select a Client and Project..
                </Typography>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  margin="dense"
                  options={clientOptions}
                  onChange={(e, new_value) => setClientState(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Client" />
                  )}
                  value={clientState}
                  sx={{ minWidth: 275 }}
                />
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  options={projectOptions}
                  onChange={(e, new_value) => setProjectState(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Project" />
                  )}
                  value={projectState}
                  disabled={!clientState}
                />
              </FormContainer>
            </Grid>
            {/* file choose and upload section */}
            <Grid item xs={12} md={8}>
              <Typography>
                Quickly{" "}
                <Button
                  disabled={clientState && projectState ? false : true}
                  onClick={downloadTemplate}
                  style={{
                    textTransform: "capitalize",
                    padding: "0",
                    margin: "-1px 0 0 0",
                    fontSize: "0.85rem",
                  }}
                >
                  Download
                </Button>{" "}
                the template file
              </Typography>

              <FileUploadBulkEdit
                files={files}
                setFiles={setFiles}
                accept={["xlsx"]}
                disabled={!projectState}
                projectState={projectState}
                clientState={clientState}
                setValue={setValue}
                handleRefresh={handleRefresh}
              />
            </Grid>
          </Grid>
        )}

        {/*  Bulk Update container */}
        {value === "two" && (
          <Grid
            container
            spacing={2}
            sx={{ mt: 1 }}
            align="center"
            justify="center"
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                paddingTop: "0px"
              }}
            >
              <Stack direction="row">
                <Typography
                  variant="h5"
                  color="secondary.main"
                  sx={{
                    marginBottom: "8px",
                  }}
                >
                  Refresh
                </Typography>
                <Tooltip
                  title="Refresh Downloads"
                  style={{ cursor: "pointer" }}
                  onClick={handleRefresh}
                >
                  <RefreshIcon
                    color="primary"
                    style={{ marginTop: "4px", marginLeft: "10px", cursor: "pointer" }}
                  ></RefreshIcon>
                </Tooltip>
              </Stack>

              <TableContainer component={Paper} variant="outlined">
                <Table size="small" aria-label="Bulk Update Table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>Client</TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>Projects</TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>Created On</TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>File Name</TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold", width: "190px", maxWidth: "190px" }}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {downloads.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center" component="td" scope="row">
                          {row.client || "null"}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {row.project || "null"}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {formatDate(row.created_on) || "null"}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {row.file_name || "null"}
                        </TableCell>
                        <TableCell align="center">
                          {(row.status === "IN PROGRESS") && (
                            <Tooltip placement="left" title="File is in progress">
                              <IconButton aria-label="Processing" color="secondary">
                                <LockResetIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {row.status === "SUCCESS" && (
                            <Tooltip placement="left" title="Ready to submit">
                              <IconButton aria-label="submit the file for bulk update" color="primary" size="medium"
                                onClick={() => handleEditSubmission(row, index)}
                                disabled={disabledButtons.includes(index)} >
                                <SendIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {row.status === "SUCCESSFULLY APPLIED" && (
                            <Tooltip placement="left" title="Download Summary Details">
                              <IconButton aria-label="downlaod summary details in .txt file" color="success" size="medium"
                                onClick={() => downloadTxtFile(row)}>
                                <SaveAltIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {row.status === "ERROR" || row.status === "FAILED" && (
                            <Tooltip placement="left" title={row.status === "ERROR" ? "Download Error Details" : "Download Failed Details"}>
                              <IconButton aria-label={row.status === "ERROR" ? "downlaod error details in .txt file" : "downlaod failed details in .txt file"}
                                size="medium" color="error" onClick={() => downloadTxtFile(row)}>
                                <SaveAltIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>


            </Grid>
          </Grid>
        )}
      </Box>
    </UIWrapper>
  );
};
export default EditFileUpload;
