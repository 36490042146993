import ArchitectureIcon from "@mui/icons-material/Architecture";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import FileUpload from "../../components/FileUpload";
import UIWrapper from "../../components/UIWrapper";
import ProjectManagementService from "../../services/ProjectManagementService";
import { mapManifestActions } from "../../Store/map-manifest";
import { snackbarActions } from "../../Store/snackbar";
import DesignManifest from "./Steps/DesignManifest";
import SaveDesign from "./Steps/SaveDesign";

const icons = {
  1: <FileUploadOutlinedIcon />,
  2: <ArchitectureIcon />,
  3: <SaveIcon />,
};

const steps = ["Upload Manifest", "Design", "Save"];

const ManifestMapper = (props) => {
  const [files, setFiles] = useState(null);
  const [sampleContent, setSampleContent] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [clientOptions, setClientOptions] = useState([]);
  const [mappingOptions, setMappingOptions] = useState([]);
  const [clientState, setClientState] = useState("");
  const [mappingState, setMappingState] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    ProjectManagementService.getAllClients("").then((resp) =>
      setClientOptions(resp.data)
    );
    return;
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        ProjectManagementService.getManifestProfiles(clientState).then((resp) =>
          setMappingOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [clientState]);

  useEffect(() => {
    if (clientState && mappingState && files) {
      ProjectManagementService.previewOrig(files)
        .then((resp) => {
          setSampleContent(resp.data);
          ProjectManagementService.getMappingProfile(clientState, mappingState)
            .then((resp) => {
              dispatch(mapManifestActions.initTemplate(resp.data));
              setActiveStep(1);
            })
            .catch((err) => {
              dispatch(
                snackbarActions.showNotification({
                  snackbarOpen: true,
                  snackbarType: "error",
                  snackbarMessage: "Failed Preview",
                })
              );
            });
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed Preview",
            })
          );
        });
    } else if (files) {
      ProjectManagementService.previewOrig(files)
        .then((resp) => {
          setSampleContent(resp.data);
          dispatch(mapManifestActions.init(Object.keys(resp.data[0])));
          setActiveStep(1);
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed Preview",
            })
          );
        });
    }
  }, [clientState, mappingState, files]);

  useEffect(() => {
    dispatch(mapManifestActions.switch());
  }, [activeStep]);

  return (
    <UIWrapper
      title="Map Manifest"
      stepper
      paper
      icons={icons}
      steps={steps}
      activeStep={activeStep}
    >
      {activeStep == 0 && (
        <Grid container spacing={2} columns={12} mt={3}>
          {/* Client & Project */}
          <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
            <Typography>Select a Template Mapping Profile</Typography>
            <FormContainer>
              <Grid container spacing={2} columns={12}>

                <Grid item xs={12}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    size="small"
                    margin="dense"
                    options={clientOptions}
                    onChange={(e, new_value) => setClientState(new_value)}
                    renderInput={(params) => (
                      <TextField margin="dense" {...params} label="Client" />
                    )}
                    value={clientState}
                    sx={{ minWidth: 275 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={mappingState}
                    onChange={(e) => setMappingState(e.target.value)}
                    select // tell TextField to render select
                    label="Profile"
                    size="small"
                    margin="dense"
                    sx={{ width: "100%" }}
                    disabled={!clientState}
                  >
                    {mappingOptions.map((mapping) => (
                      <MenuItem key={mapping.name} value={mapping.name}>
                        {mapping.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

              </Grid>
            </FormContainer>
          </Grid>

          {/* Optional Text */}
          <Grid item xs={12} sm={12} md={1} lg={1} sx={{ margin: "auto" }}>
            <Grid
              item
              xs={12}
              sx={{
                width: "50px",
                height: "50px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                color: "#fff",
                background: "#0652DD",
              }}
            >
              <Typography>{"OR"}</Typography>
            </Grid>
          </Grid>

          {/* Template Client & Project */}
          <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
            {/* <Typography>{"Select a Template Client & Project"}</Typography> */}
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ width: "100%" }}>
                  <FileUpload files={files} setFiles={setFiles} accept={["csv"]} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* DesignManifest Conditional Rendering ON STEP ONE */}
      {activeStep == 1 && (
        <DesignManifest
          files={files}
          setActiveStep={setActiveStep}
          sampleContent={sampleContent}
        />
      )}
      {/* SaveDesign Conditional Rendering ON STEP TWO */}
      {activeStep == 2 && <SaveDesign setActiveStep={setActiveStep} />}
    </UIWrapper>
  );
};

export default ManifestMapper;
