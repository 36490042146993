import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import FileUpload from "../../components/FileUpload";
import PreviewTable from "../../components/ManifestBot/PreviewTable";
import UIWrapper from "../../components/UIWrapper";
import OnHoldService from "../../services/OnHoldService";
import BiService from "../../services/BiService";
import { snackbarActions } from "../../Store/snackbar";
import { useTheme } from "@mui/material/styles";

const IngestOnHoldSamples = () => {
  const [clientOptions, setClientOptions] = useState([]);
  const [files, setFiles] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [clientState, setClientState] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const downloadTemplate = () => {
    OnHoldService.downloadIngestTemplate()
      .then((resp) => {
        const url = resp.data.presigned_url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template");
        document.body.appendChild(link);
        link.click();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successfully downloaded the template file",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
    return;
  };

  useEffect(() => {
    if (files) {
      setPreviewData(null);
      OnHoldService.previewOnHoldFile(files)
        .then((resp) => {
          resp.data.length
            ? setPreviewData(resp.data)
            : dispatch(
                snackbarActions.showNotification({
                  snackbarOpen: true,
                  snackbarType: "error",
                  snackbarMessage: "Uploaded file is either empty or invalid.",
                })
              );
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
    }
  }, [files]);

  useEffect(() => {
    OnHoldService.getOnHoldClients()
      .then((resp) => setClientOptions(resp.data))
      .catch((err) => {
        console.log(err);
      });
    return;
  }, []);

  const onIngest = async () => {
    if (files && clientState) {
      OnHoldService.ingestOnHoldSamplesFile(files, clientState)
        .then((resp) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: resp.data,
            })
          );
          setConfirmation(false);
          setClientState("");
          setPreviewData(null);
          setFiles(null);
        })
        .catch((err) => {
          setConfirmation(false);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage:
                typeof err?.response?.data?.detail === "string"
                  ? err.response.data.detail
                  : "Something went wrong.",
            })
          );
        });
    }
  };

  return (
    <UIWrapper title="SampleSphere On-Hold Samples Ingestion & Export" paper>
      <Typography
        variant="h6"
        color={theme.palette.mode == "light" ? "secondary.dark" : "#c9d1d9"}
        sx={{ mt: 2, mb: 1 }}
      >
        Please Select a Client and Upload a file for On-Hold Sample Ingestion.
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ mt: 1 }}
        align="center"
        justify="center"
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormContainer>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              size="small"
              margin="dense"
              options={clientOptions}
              onChange={(e, new_value) => setClientState(new_value)}
              renderInput={(params) => (
                <TextField margin="dense" {...params} label="Client" />
              )}
              value={clientState}
              sx={{ minWidth: 275 }}
            />
          </FormContainer>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid item xs={12} md={12}>
            <Typography>
              Quickly{" "}
              <Button
                // disabled={clientState && projectState ? false : true}
                onClick={downloadTemplate}
                style={{
                  textTransform: "capitalize",
                  padding: "0",
                  margin: "-1px 0 0 0",
                  fontSize: "0.85rem",
                }}
              >
                Download
              </Button>{" "}
              the template file.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <FileUpload
              files={files}
              setFiles={setFiles}
              accept={["csv"]}
              disabled={!clientState}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {files?.name && (
              <Button
                sx={{ cursor: "default", marginTop: "10px", float: "right" }}
                variant="outlined"
                component="label"
                onClick={() => {}}
              >
                {files.name}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PreviewTable content={previewData} emptyTitle={"Preview Data"} />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDoubleArrowDownIcon />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => setConfirmation(true)}
            disabled={!files || !clientState || !previewData}
          >
            Ingest
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={confirmation}
        handleClose={() => setConfirmation(false)}
        handleConfirm={onIngest}
        title="Ingest this On-Hold Samples?"
        subTitle="Confirming will accession these samples into On Hold SampledSphere! 
        Have you verified that all of the information in the manifest is valid and correct?"
      />
    </UIWrapper>
  );
};

export default IngestOnHoldSamples;
