import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PersonIcon from "@mui/icons-material/Person";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const ReviewSteps = ({
  client,
  project,
  coreMapping,
  rows,
  setActiveStep,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const [submitState, setSubmitState] = useState(false);

  //   dispatch(
  //     snackbarActions.showNotification({
  //       snackbarOpen: true,
  //       snackbarType: "error",
  //       snackbarMessage: "Can't Remove Required Field",
  //     })
  //   );

  const columns = [
    {
      field: "stage",
      headerName: "Stage",
      editable: false,
      width: 180,
    },
    {
      field: "orig_name",
      headerName: "SampledSphere Header",
      editable: false,
      width: 240,
    },
    {
      field: "client_name",
      headerName: "Client Header",
      editable: false,
      width: 240,
    },
    {
      field: "auto_update",
      headerName: "If Meta, Automated Updates",
      editable: false,
      type: "boolean",
      width: 100,
    },
    {
      field: "is_inheritable",
      headerName: "If Meta, set inheritance",
      editable: false,
      type: "boolean",
      width: 100,
    },
    {
      field: "required",
      headerName: "Required",
      editable: false,
      type: "boolean",
      width: 100,
    },
    {
      field: "hierarchy",
      headerName: "Hierarchy",
      editable: false,
      type: "singleSelect",
      width: 200,
      valueOptions: ["subject", "sample"],
      renderCell: (params) => {
        if (params.value === "subject") {
          return (
            <Button
              color="success"
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <PersonIcon /> &nbsp; Subject
            </Button>
          );
        } else if (params.value === "sample") {
          return (
            <Button
              color="info"
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <VaccinesIcon />
              &nbsp; Sample
            </Button>
          );
        } else {
          return (
            <Button
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <NotInterestedIcon />
              &nbsp; Do Not Change
            </Button>
          );
        }
      },
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2} justifyContent="center">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {"Client Selection"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              label="Client"
              defaultValue={client}
              size="small"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
              sx={{ mr: 2 }}
            />
            <TextField
              label="Project"
              defaultValue={project}
              size="small"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
              sx={{ ml: 2 }}
            />
          </Box>
          <Divider sx={{ mt: 2 }} orientation="horizontal" />
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {"Core Mapping"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} md={1} mt={2} justifyContent="center" />
        <Grid item xs={12} md={2} mt={2} justifyContent="center">
          <TextField
            label="client"
            defaultValue={coreMapping.client}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={2} mt={2} justifyContent="center">
          <TextField
            label="project"
            defaultValue={coreMapping.project}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={2} mt={2} justifyContent="center">
          <TextField
            label="inventory_code"
            defaultValue={coreMapping.inventory_code}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={2} mt={2} justifyContent="center">
          <TextField
            label="parent_barcode"
            defaultValue={coreMapping.parent_barcode}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={2} mt={2} justifyContent="center">
          <TextField
            label="ultimate_parent"
            defaultValue={coreMapping.ultimate_parent}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Divider orientation="horizontal" />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {"Stage Mapping"}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} alignItems="center" justifyContent="center">
          <Box
            // sx={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            // }}
          >
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                sx={{
                  // width: columns.reduce(
                  //   (prev, current) => prev + current.width,
                  //   0
                  // ),
                  height: 600,
                  width: "95%",
                  maxWidth: "95%",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} container justifyContent="start">
          <Button
            onClick={() => {
              setActiveStep((init) => init - 1);
            }}
          >
            &lt; Back
          </Button>
        </Grid>
        <Grid item xs={6} container justifyContent="end">
          <Button
            onClick={() => {
              setSubmitState(true);
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={submitState}
        handleClose={() => setSubmitState(false)}
        handleConfirm={onSubmit}
        title="Add this project?"
        subTitle="Confirming will create this Project in SampledSphere and allow for samples to be assessioned using this format. Are you sure all of the information entered is correct?"
      />
    </>
  );
};

export default ReviewSteps;
