import { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "../mui";
import CryptoJS from "crypto-js";
import UIWrapper from "../components/UIWrapper";
import { Grid } from "@mui/material";
import AdminService from "../services/AdminService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import { Editor } from "@tinymce/tinymce-react";
import authImg from "../assets/unauth-img.jpg";
import CircularProgress from "@mui/material/CircularProgress";

const ServiceCreateBulletin = () => {
  const dispatch = useDispatch();
  const [bulletinData, setBulletinData] = useState("");
  const [initialBulletinData, setInitialBulletinData] = useState("");
  const [authMsg, setAuthMsg] = useState("");
  const [loadingIcon, setLoadingIcon] = useState(true);

  const secretPass = "ljhREQc6QWsdfNVB";

  const encryptData = (text) =>
    CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString();

  const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const submitData = () => {
    const newBulletinData = encryptData(bulletinData);
    AdminService.createBulletin({ bulletin_data: newBulletinData })
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data,
          })
        );
      });
  };

  const onChange = (e) => {
    setBulletinData(e.target.getContent());
  };

  useEffect(() => {
    let page = "editor";
    AdminService.getBulletinData(page)
      .then((resp) => {
        setInitialBulletinData(decryptData(resp.data.bulletin_data));
        setBulletinData(decryptData(resp.data.bulletin_data));
        setLoadingIcon(false);
      })
      .catch((err) => {
        setAuthMsg(err.response.data.detail);
        setLoadingIcon(false);
      });
  }, []);

  return (
    <>
      {loadingIcon ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "200px 0 0 0",
          }}
        >
          <CircularProgress />
        </Box>
      ) : authMsg === "" ? (
        <UIWrapper title="Bulletin" paper>
          <Box>           
            <Grid
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ margin: "10px 0" }}
            >              
              <Editor
                initialValue={initialBulletinData}
                apiKey="x24083ipet425ljlphp5rgeyrjebht5s2xvbieu04f8i63o6"
                init={{
                  branding: false,
                  height: 400,
                  menubar: true,
                  plugins:
                    "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                  toolbar:
                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                  image_advtab: true,
                }}
                onChange={onChange}
              />
              <Stack direction="row" style={{ width: "100%", marginTop: "20px", display:"flex", justifyContent: "center" }}>
                <Button
                  style={{ width: "15%"}}
                  type="submit"
                  variant="contained"
                  onClick={submitData}
                >
                  Create Bulletin
                </Button>
              </Stack>
            </Grid>
          </Box>{" "}
        </UIWrapper>
      ) : (
        <Grid container rowSpacing={1} style={{ margin: "10px 0" }}>
          <Stack
            direction="row"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <img src={authImg} alt="401" width={500} height={500} />
          </Stack>
          <Stack
            direction="row"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Typography
              variant="h5"
              color="secondary.main"
              sx={{
                marginBottom: "8px",
              }}
            >
              {authMsg}
            </Typography>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default ServiceCreateBulletin;
