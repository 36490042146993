import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export default function AcsNav(props) {
  const theme = useTheme();

  return (
    <MobileStepper
      variant="dots"
      steps={4}
      position="static"
      activeStep={props.activeStep}
      sx={{ width: "100%", flexGrow: 1, padding: "0px" }}
      nextButton={
        props.activeStep !== 5 ? (
          <Button
            size="small"
            onClick={props.handleNext}
            disabled={
              props.activeStep !== 3 &&
              props.client &&
              props.project &&
              props.trackingNumber &&
              // props.subjectCode &&
              props.formListHeader.site &&
              props.formListHeader.carrier &&
              props.formListHeader.date_received &&
              props.formListHeader.dt_received_timezone
                ? false
                : true
            }
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        ) : (
          <Button
            size="small"
            onClick={props.onScanningComplete}
            disabled={
              props.client &&
              props.project &&
              props.trackingNumber &&
              props.formListHeader.site &&
              props.formListHeader.carrier
                ? false
                : true
            }
          >
            Done
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        )
      }
      backButton={
        <Button
          size="small"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  );
}
