import { useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PersonIcon from "@mui/icons-material/Person";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import { Box, Button, Fab, Grid, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../../../Store/snackbar";
import AddAccRowDialog from "./AddAccRowDialog";
import AddRowDialog from "./AddRowDialog";
import { Checkbox } from "../../../../mui";

const data_type_mapping = {
  string: { label: "Text", color: "success" },
  date: { label: "Date", color: "warning" },
  number: { label: "Number", color: "secondary" },
  boolean: { label: "True/False", color: "info" },
  time: { label: "Time", color: "primary" },
  float: { label: "Decimal", color: "secondary" },
};

const StepTemplate = ({
  step,
  setActiveStep,
  rows,
  setRows,
  allowAddField,
  directions,
}) => {
  const dispatch = useDispatch();
  const [newRow, setNewRow] = useState(false);

  const handleCommit = (e) => {
    console.log(e);
    const new_rows = rows.map((r) => {
      if (r.id === e.id) {
        return { ...r, [e.field]: e.value };
      } else {
        return { ...r };
      }
    });
    setRows(new_rows);
  };

  const handleDeleteClick = (identify) => () => {
    const check_delete = rows.find((obj) => obj.id == identify);
    if (check_delete.required) {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Can't Remove Required Field",
        })
      );
    } else {
      setRows((init) =>
        init
          .filter((row) => row.id !== identify)
          .map((row, index) => {
            return { ...row, id: index };
          })
      );
    }
  };

  const handleAutoUpdate = (checked, identify) => {
    setRows((init) =>
      init.map((row) => {
        if (row.id === identify) {
          return { ...row, auto_update: checked };
        } else return row;
      })
    );
  };

  const handleMetaInheritance = (checked, identify) => {
    setRows((init) =>
      init.map((row) => {
        if (row.id === identify) {
          return { ...row, is_inheritable: checked };
        } else return row;
      })
    );
  };
  // console.log(rows);

  const stepColumns = [
    {
      field: "orig_name",
      headerName: "SampledSphere Header",
      editable: false,
      width: 250,
    },
    {
      field: "client_name",
      headerName: "Client Header",
      editable: true,
      width: 250,
    },
    {
      field: "service_name",
      headerName: "Services",
      width: 250,
      editable: true,
      type: "singleSelect",
      valueGetter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value;
      },
      valueOptions: ["WB PL APT WBC", " WB PL APT RBC"],
    },
    {
      field: "auto_update",
      headerName: "If Meta, Automated Updates",
      type: "singleSelect",
      width: 120,
      renderCell: ({ id, row }) => {
        if (row.is_meta) {
          return (
            <Checkbox
              key={id}
              checked={!!row.auto_update}
              onChange={(event) => {
                handleAutoUpdate(event.target.checked, id);
              }}
            />
          );
        } else {
          return (
            <Checkbox
              disabled
              key={id}
              sx={{ "&.Mui-disabled": { color: "#e2e3e2" } }}
            />
          );
        }
      },
    },
    {
      field: "is_inheritable",
      headerName: "If Meta, set inheritance",
      // editable: true,
      type: "singleSelect",
      width: 120,
      renderCell: ({ id, row }) => {
        // console.log("parm", id, is_meta, row);
        if (row.is_meta) {
          return (
            <Checkbox
              key={id}
              checked={!!row.is_inheritable}
              onChange={(event) => {
                handleMetaInheritance(event.target.checked, id);
              }}
            />
          );
        } else {
          return (
            <Checkbox
              disabled
              key={id}
              sx={{ "&.Mui-disabled": { color: "#e2e3e2" } }}
            />
          );
        }
      },
    },
    {
      field: "required",
      headerName: "Required",
      editable: true,
      type: "boolean",
      width: 100,
    },
    {
      field: "hierarchy",
      headerName: "Hierarchy",
      editable: true,
      type: "singleSelect",
      width: 200,
      valueOptions: ["subject", "sample"],
      renderCell: (params) => {
        if (params.value === "subject") {
          return (
            <Button
              color="success"
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <PersonIcon /> &nbsp; Subject
            </Button>
          );
        } else if (params.value === "sample") {
          return (
            <Button
              color="info"
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <VaccinesIcon />
              &nbsp; Sample
            </Button>
          );
        } else {
          return (
            <Button
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <NotInterestedIcon />
              &nbsp; Do Not Change
            </Button>
          );
        }
      },
    },
    {
      field: "data_type",
      headerName: "Data Type",
      editable: true,
      type: "singleSelect",
      width: 100,
      valueOptions: Object.keys(data_type_mapping),
      renderCell: (params) => {
        if (Object.keys(data_type_mapping).includes(params.value)) {
          return (
            <Button
              color={data_type_mapping[params.value].color}
              variant="contained"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              {data_type_mapping[params.value].label}
            </Button>
          );
        } else {
          return (
            <Button
              variant="outlined"
              sx={{
                borderRadius: 100,
                pointerEvents: "none",
                textTransform: "none",
              }}
            >
              <NotInterestedIcon />
            </Button>
          );
        }
      },
    },
    {
      field: "delete",
      headerName: "",
      type: "actions",
      width: 50,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<DeleteForeverIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  const columns = useMemo(() => {
    if (step == "aliquot" || step == "qc" || step == "post" || step == "su") {
      return stepColumns
        .slice(0, 2)
        .concat(stepColumns[stepColumns.length - 1]);
    } else if (step === "services") {
      return stepColumns
        .slice(0, 3)
        .concat(stepColumns[stepColumns.length - 1]);
    } else {
      return stepColumns;
    }
  }, [step]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2} justifyContent="center">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{ padding: "0 80px" }}
              justifyContent="center"
              align="center"
            >
              {directions}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} alignItems="center" justifyContent="center">
          <Box
            // sx={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            // }}
          >
            <Box>
              <DataGrid
                rows={rows}
                columns={columns}
                onCellEditCommit={(e) => handleCommit(e)}
                sx={{
                  // width: columns.reduce(
                  //   (prev, current) => prev + current.width,
                  //   0
                  // ),
                  height: 600,
                  width: "100%",
                  maxWidth: "100%",
                }}
                // experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </Box>
        </Grid>
        {allowAddField && (
          <Grid
            item
            sm={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              size="small"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => setNewRow(true)}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Field&nbsp;
            </Fab>
          </Grid>
        )}
        <Grid item xs={6} container justifyContent="start">
          <Button
            onClick={() => {
              setActiveStep((init) => init - 1);
            }}
          >
            &lt; Back
          </Button>
        </Grid>
        <Grid item xs={6} container justifyContent="end">
          <Button
            onClick={() => {
              setActiveStep((init) => init + 1);
            }}
          >
            Next &gt;
          </Button>
        </Grid>
      </Grid>
      {step == "accessioning" && (
        <AddAccRowDialog
          open={newRow}
          setRows={setRows}
          onClose={() => setNewRow(false)}
        />
      )}
      {step == "post" && (
        <AddRowDialog
          open={newRow}
          setRows={setRows}
          onClose={() => setNewRow(false)}
        />
      )}
    </>
  );
};

export default StepTemplate;
