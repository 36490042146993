import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import {
  Autocomplete,
  FormControl,
  Button,
  Box,
  CircularProgress,
  TextField,
  Typography,
  Stack,
  MenuItem,
  Select,
  InputLabel,
} from "../../mui";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { FormContainer } from "react-hook-form-mui";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { snackbarActions } from "../../Store/snackbar";

import BiService from "../../services/BiService";
import Header from "../acsComp/Header";

const filterInputs = {
  field_name: "",
  condition: "",
  value: "",
};

const AcsEditFilter = ({ tableBulkData, setTableBulkData, setValue }) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientState, setClientState] = useState("");
  const [projectState, setProjectState] = useState("");
  const [filtersCondition, setFiltersCondition] = useState([]);

  const [filterView, setFilterView] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);

  const [loadingExport, setLoadingExport] = useState(false);
  const profileRef = useRef({});

  const [selection, setSelection] = useState("tracked");
  const dispatch = useDispatch();

  const handleSubmission = async () => {
    let temp = [];
    let isFilterValueEmpty = false;
    filterList.forEach((ele, index) => {
      Object.values(filterList[index]).forEach((option) => {
        if (option === "") isFilterValueEmpty = true;
      });
      temp.push([
        filtersCondition[index].toLowerCase(),
        ...Object.values(filterList[index]),
      ]);
    });

    profileRef.current = {
      client: clientState,
      projects: projectState,
      filters: temp,
      selection,
    };

    if (isFilterValueEmpty) {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "A Filter Value is Empty",
        })
      );
    } else {
      setDataLoading(true);
      await BiService.fetchSmapleData(profileRef.current)
        .then((resp) => {
          setTableBulkData(resp.data.records);
          let msg =
            resp.data.records_count > 500
              ? `Displaying latest 500 samples out of the ${resp.data.records_count} samples found for the given search. Please export to get all the results.`
              : `Displaying all ${resp.data.records_count} samples found for the given search.`;
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: msg,
            })
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed to Search Samples",
            })
          );
        });
      setDataLoading(false);
    }
  };
  const handleChangeArray = (value, index) => {
    let clone = filtersCondition.slice(0);
    clone[index] = value;
    setFiltersCondition(clone);
  };
  const handleFilterEvent = (event) => {
    event.preventDefault();
  };
  const changeFilterField = (index, newVal) => {
    let newList = [...filterList];
    newList[index].field_name = newVal;
    newList[index]["condition"] = "";
    newList[index]["value"] = "";

    setFilterList(newList);
  };
  const changeFilterFil = (index, newVal) => {
    let newList = [...filterList];
    newList[index]["condition"] = newVal;
    setFilterList(newList);
  };
  const changeFilterVal = (e, index) => {
    let newList = [...filterList];
    newList[index]["value"] = e.target.value;
    setFilterList(newList);
  };

  const handleDateChange = (index, newValue) => {
    const dte = dayjs(newValue.$d).format("YYYY-MM-DD");
    let newList = [...filterList];

    newList[index]["value"] = dte;
    setFilterList(newList);
  };
  const handleFilterDelete = (eleIndex) => {
    let temp = [...filterList];
    temp.splice(eleIndex, 1);
    setFilterList(temp);
  };

  const addFilter = (e) => {
    if (filterInputs) {
      let copy = filtersCondition.slice(0);
      copy.push("AND");
      setFiltersCondition(copy);

      let newList = filterList.slice(0);
      newList.push(JSON.parse(JSON.stringify(filterInputs)));
      setFilterList(newList);
    }
  };

  const handleViewChange = (value) => {
    if (value === "Filter") setFilterView((prev) => !prev);
  };

  const handleExport = async (event) => {
    setLoadingExport(true);
    await BiService.exportAdvancedSearch(profileRef.current)
      .then((resp) => {
        // setExportData(resp.data);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setValue("three");
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Sample Data Export Failed",
          })
        );
      });
    setLoadingExport(false);
  };

  useEffect(() => {
    BiService.getAllClients("")
      .then((resp) => setClientOptions(resp.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setProjectState("");
    BiService.searchProjects(clientState, projectState)
      .then((resp) => setProjectOptions(resp.data))
      .catch((err) => {
        console.log(err);
      });
  }, [clientState]);

  useEffect(() => {
    BiService.getFiltersAdvancedSearch()
      .then((resp) => {
        //   setSearchedDetail(true);
        setFilterData(resp.data);
        // if (finalFilter.length) setFilterList(finalFilter);
        //   setMappingView(true);
      })
      .catch((err) => {
        console.log(err);
        //   setSearchedDetail(false);
      });
  }, []);

  return (
    <div>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ mt: 1 }}
          align="center"
          justify="center"
        >
          {dataLoading === true && (
            <Box>
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "62%",
                  left: "55%",
                  transform: "translate(-62%, -55%)",
                }}
                size={"4rem"}
              />
            </Box>
          )}

          <Typography
            color="rgba(0, 0, 0, 0.6)"
            style={{ marginTop: "15px", marginLeft: "25px" }}
          >
            Select a Client and Project
          </Typography>

          <Grid container spacing={2} sx={{ml:1}}>
            <Grid item xs={5}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                margin="dense"
                options={clientOptions}
                onChange={(e, new_value) => setClientState(new_value)}
                renderInput={(params) => (
                  <TextField margin="dense" {...params} label="Client" />
                )}
                value={clientState}
                sx={{ minWidth: 275 }}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                options={projectOptions}
                onChange={(e, new_value) => setProjectState(new_value)}
                renderInput={(params) => (
                  <TextField margin="dense" {...params} label="Project" />
                )}
                value={projectState}
                disabled={!clientState}
              />
            </Grid>
            <Grid item xs={2}>
              {tableBulkData.length !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    marginTop: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="blue"
                    disabled={loadingExport}
                    onClick={handleExport}
                  >
                    {loadingExport ? (
                      <Grid container direction="row" gap="10px">
                        <Grid item> Exporting</Grid>
                        <Grid item>
                          <CircularProgress size={"1rem"} />
                        </Grid>
                      </Grid>
                    ) : (
                      "Export"
                    )}
                  </Button>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                disabled={!(clientState && projectState)}
                type="submit"
                variant="contained"
                onClick={handleSubmission}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Header
              header={"Filter"}
              selected={filterView}
              handleViewChange={handleViewChange}
            />
            <Stack
              style={{
                padding: "15px",
                display: filterView ? "block" : "none",
                overflow: "auto",
                maxHeight: "300px",
              }}
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                sx={{ width: "99%" }}
              >
                <Button
                  onClick={(e) => addFilter(e)}
                  style={{ cursor: "pointer" }}
                >
                  <AddCircleOutlineIcon
                    fontSize="medium"
                    style={{
                      color: "#9b51e0",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      cursor: "pointer",
                    }}
                    // onClick={() => setFilterList(filterList => [...filterList, 1])}
                  ></AddCircleOutlineIcon>
                  <Typography variant="body1" color="secondary">
                    Add Filter
                  </Typography>
                </Button>
              </Stack>

              <form onSubmit={handleFilterEvent}>
                {Object.keys(filterList).map((ele, index) => (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    key={index}
                  >
                    {filterList.length !== 0 && index !== 0 && (
                      <FormControl
                        variant="standard"
                        sx={{ width: "8%", marginTop: "5px" }}
                      >
                        <InputLabel id="and-or-id">AND/OR</InputLabel>
                        <Select
                          labelId="and-or-id"
                          label="AND/OR"
                          value={filtersCondition[index]}
                          onChange={(event) =>
                            handleChangeArray(event.target.value, index)
                          }
                          sx={{ minWidth: "32px" }}
                        >
                          <MenuItem value={"AND"}>AND</MenuItem>
                          <MenuItem value={"OR"}>OR</MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ width: "100%" }}
                      key={index}
                    >
                      <Autocomplete
                        id="free-solo-demo"
                        sx={{ width: "20%" }}
                        freeSolo
                        size="small"
                        margin="dense"
                        options={Object.keys(filterData)}
                        onChange={(e, new_value) =>
                          changeFilterField(index, new_value)
                        }
                        renderInput={(params) => (
                          <TextField
                            margin="dense"
                            {...params}
                            label="Field Name"
                          />
                        )}
                        value={filterList[index]["field_name"] || ""}
                      />

                      <ArrowRightAltIcon
                        fontSize="large"
                        style={{
                          color: "#9b51e0",
                          marginTop: "4px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      ></ArrowRightAltIcon>

                      <Autocomplete
                        id="free-solo-demo"
                        sx={{ width: "20%" }}
                        freeSolo
                        size="small"
                        margin="dense"
                        options={
                          filterList[index]["field_name"]
                            ? filterData[filterList[index]["field_name"]][
                                "conditions"
                              ]
                            : []
                        }
                        onChange={(e, new_value) =>
                          changeFilterFil(index, new_value.toLowerCase())
                        }
                        renderInput={(params) => (
                          <TextField
                            margin="dense"
                            {...params}
                            label="Condition"
                          />
                        )}
                        value={
                          filterList[index]["condition"] || ""
                          // ? filterList[index]["condition"].toLowerCase()
                          // : ""
                        }
                        disabled={!filterList[index]["field_name"]}
                      />

                      <ArrowRightAltIcon
                        fontSize="large"
                        style={{
                          color: "#9b51e0",
                          marginTop: "4px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      ></ArrowRightAltIcon>

                      <FormControl variant="standard" sx={{ width: "20%" }}>
                        {filterList[index]["field_name"] &&
                        filterList[index]["condition"] ? (
                          filterData[filterList[index]["field_name"]][
                            "type"
                          ] === "date" ? (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                // label="Select Date"
                                inputFormat="YYYY-MM-DD"
                                value={dayjs(filterList[index]["value"])}
                                onChange={(newValue) => {
                                  handleDateChange(index, dayjs(newValue));
                                }}
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    inputProps={{
                                      ...props.inputProps,
                                      readOnly: true,
                                    }}
                                    helperText="Select Date(yyyy-mm-dd)"
                                    placeHolderText="YYYY-MM-DD"
                                    variant="standard"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          ) : (
                            <TextField
                              label="Filter Value"
                              variant="standard"
                              helperText={
                                //   filterList[index]["field_name"]
                                filterList[index]["value"]
                                  ? ""
                                  : "Please enter a value"
                              }
                              error={filterList[index]["value"] ? false : true}
                              InputProps={{
                                inputProps: {
                                  type: "text",
                                },
                              }}
                              onChange={(e, new_value) =>
                                // if (e.target.validity.valid || e.target.value === '') setValue(e.target.value)
                                changeFilterVal(e, index, new_value)
                              }
                              value={filterList[index]["value"] || ""}
                            />
                          )
                        ) : (
                          <TextField
                            label={"Filter Value"}
                            variant="standard"
                            helperText="Please select a field"
                            disabled
                          />
                        )}
                      </FormControl>

                      <BackspaceIcon
                        fontSize="medium"
                        style={{
                          color: "#EE3263",
                          marginTop: "4px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleFilterDelete(index)}
                      ></BackspaceIcon>
                    </Stack>
                  </Stack>
                ))}
              </form>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AcsEditFilter;
