import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Box,
  Button,
  Stack,
  Typography,
  ExpandMoreIcon,
  Checkbox,
  FormControlLabel,
  Paper,
  Divider,
  Tab,
  Tabs,
} from "../../mui";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import AdminService from "../../services/AdminService";
import UserRoleMangement from "./UserRoleMangement";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60%",
  minHeight: "87vh",
  maxHeight: "87vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  overflow: "auto",
};

const tableRowData = [
  "Tracking Number",
  "Client",
  "Number of Samples",
  "Created By",
];

const EditUserAccess = ({ row, allClientsProjects }) => {
  const [tab, setTab] = useState("one");
  
  const [processingClientSave, setProcessingClientSave] = useState(false);
  const [clients_projects, setClients_projects] = useState(
    row.clients_projects || {}
  );

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleTabChange = (e, new_value) => {
    setTab(new_value);
  };

  const { mutate: mutateUserAccess } = useMutation({
    mutationFn: (body) => AdminService.editUserAccessAndRoles(body),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get_all_users_access"] });
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: data,
        })
      );
    },
    onError: (error) => {
      console.log(error);
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            typeof error?.response?.data?.detail === "string"
              ? error.response.data.detail
              : "Something went wrong.",
        })
      );
    },
    onSettled: () => {
      setProcessingClientSave(false);
    },
  });

  const handleSave = () => {
    const new_clients_projects = Object.keys(clients_projects).reduce(
      (acc, curr) => {
        if (clients_projects[curr]?.length) {
          acc[curr] = clients_projects[curr];
        }
        return acc;
      },
      {}
    );
    setProcessingClientSave(true);
    mutateUserAccess({ ...row, ["clients_projects"]: new_clients_projects });
  };
  const handleCheckChangeProj = (client_name, proj) => {
    let tempArr = clients_projects[client_name]
      ? clients_projects[client_name]
      : [];
    if (tempArr.includes(proj)) {
      tempArr = clients_projects[client_name].filter((el) => el !== proj);
    } else tempArr.push(proj);
    setClients_projects((prev) => {
      return {
        ...prev,
        [client_name]: tempArr,
      };
    });
  };
  const handleCheckChangeClient = (e, client_name) => {
    if (e.target.checked) {
      setClients_projects((prev) => {
        return {
          ...prev,
          [client_name]: allClientsProjects[client_name],
        };
      });
    } else
      setClients_projects((prev) => {
        return {
          ...prev,
          [client_name]: [],
        };
      });
  };

  const handleCheckAll = () => {
    setClients_projects(allClientsProjects);
  };
  const handleUnCheckAll = () => {
    setClients_projects({});
  };

  return (
    <Stack sx={style}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab value="one" label="Update User Roles" />
        <Tab value="two" label="Update client Access" />
      </Tabs>
      <Box
        component={Paper}
        variant="outlined"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "none",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",
            marginTop: "8px",
          }}
        >
          <Typography variant="h6">
            <b>Full Name : </b>
            {row.full_name}
          </Typography>

          <Typography variant="h6">
            <b>Username : </b> {row.username}
          </Typography>
        </div>

        <Divider width="100%" sx={{ margin: "10px 0" }} />

        {tab === "one" ? (
          <UserRoleMangement
            group={row?.user_type}
            username={row?.username}
          />
        ) : (
          <Stack
            direction="column"
            gap={2}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "90%",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "230px",
                }}
              >
                <Button variant="outlined" width="30%" onClick={handleCheckAll}>
                  Check All
                </Button>
                <Button
                  variant="outlined"
                  width="30%"
                  sx={{
                    marginLeft: "20px",
                  }}
                  onClick={handleUnCheckAll}
                >
                  Uncheck All
                </Button>
              </Stack>
              <Button
                disabled={processingClientSave}
                width="20%"
                variant="contained"
                onClick={handleSave}
                color="success"
              >
                Save Access
              </Button>
            </Stack>

            <Paper
              sx={{
                width: "90%",
                height: "65vh",
                overflow: "auto",
              }}
              elevation={0}
              variant="outlined"
            >
              {Object.keys(allClientsProjects).map((client_name, index) => (
                <Stack
                  direction="column"
                  key={index}
                  sx={{ width: "98%", padding: "5px" }}
                >
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      border: "1px solid #c7c7c7",
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        height: "35px",
                        minHeight: "35px",
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel1-content" + { index }}
                      id={"panel1-header" + { index }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              clients_projects
                                ? JSON.stringify(
                                    clients_projects[client_name]?.sort()
                                  ) ===
                                  JSON.stringify(
                                    allClientsProjects[client_name]?.sort()
                                  )
                                : false
                            }
                            name={client_name}
                            indeterminate={
                              clients_projects[client_name]?.length > 0 &&
                              JSON.stringify(
                                clients_projects[client_name]?.sort()
                              ) !==
                                JSON.stringify(
                                  allClientsProjects[client_name]?.sort()
                                )
                            }
                            onChange={(e) =>
                              handleCheckChangeClient(e, client_name)
                            }
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontWeight: "bold",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography variant="h6" style={{ color: "#0652DD" }}>
                            {client_name}
                          </Typography>
                        }
                      />
                    </AccordionSummary>

                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          ml: "2rem",
                        }}
                      >
                        <Grid container spacing={2}>
                          {allClientsProjects[client_name].map((proj) => (
                            <Grid item xs={12} md={6} lg={4} key={proj} sx={{paddingTop: "0"}}>
                              <FormControlLabel
                                key={proj}
                                control={
                                  <Checkbox
                                    checked={
                                      clients_projects[client_name]?.includes(
                                        proj
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleCheckChangeProj(client_name, proj)
                                    }
                                    name={proj}
                                  />
                                }
                                label={proj}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              ))}
            </Paper>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default EditUserAccess;
