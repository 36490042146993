import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { Stack, Paper, Tooltip } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import BiService from "../services/BiService";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../Store/snackbar";
import LockResetIcon from "@mui/icons-material/LockReset";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useInterval } from "../components/helper/utils";
import CloseIcon from "@mui/icons-material/Close";

const ExportDownloads = ({ type }) => {
  const [downloads, setSetDownloads] = React.useState([]);
  const dispatch = useDispatch();

  const handleDownload = (file) => {
    BiService.getFile(file)
      .then((resp) => {
        console.log(resp.data);
        window.location.replace(resp.data);
        // setSetDownloads(resp.data)
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successful Download",
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Download",
          })
        );
      });
  };
  const handleRefresh = (file) => {
    BiService.getDownloads(type)
      .then((resp) => {
        console.log(resp);
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useInterval(() => {
    BiService.getDownloads(type)
      .then((resp) => {
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 1000 * 8);

  useEffect(() => {
    BiService.getDownloads(type)
      .then((resp) => {
        // console.log(resp.data);
        setSetDownloads(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box style={{ padding: "20px" }}>
      <Stack direction="row">
        <Typography
          variant="h5"
          color="secondary.main"
          sx={{
            marginBottom: "8px",
          }}
        >
          Export Downloads
        </Typography>
        <Tooltip
          title="Refresh Downloads"
          style={{ cursor: "pointer" }}
          onClick={handleRefresh}
        >
          <RefreshIcon
            color="primary"
            style={{ marginTop: "4px", marginLeft: "10px", cursor: "pointer" }}
          ></RefreshIcon>
        </Tooltip>
      </Stack>

      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ fontWeight: "bold" }}>Bucket</TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>Client</TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>Projects</TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>Created On</TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>File Name</TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>Custom Export</TableCell>
              <TableCell align="center" style={{ fontWeight: "bold" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {downloads.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="td" scope="row">
                  {row.bucket || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {row.client || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {row.projects || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {row.created_on || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {row.file_name || "null"}
                </TableCell>
                <TableCell align="center" component="td" scope="row">
                  {row.is_custom_export ? (
                    <CheckRoundedIcon fontSize="small" />
                  ) : (
                    <ClearRoundedIcon fontSize="small" />
                  )}
                </TableCell>

                <TableCell
                  align="center"
                  // onClick={(e) =>
                  //     handleDetailData(row)
                  // }
                >
                  {row.status === "SUCCESS" && (
                    <Tooltip
                      title="Ready to be Downloaded"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDownload(row.file_name)}
                    >
                      <SaveAltIcon color="green"></SaveAltIcon>
                    </Tooltip>
                  )}
                  {(row.status === "QUEUED" ||
                    row.status === "IN PROGRESS") && (
                    <Tooltip
                      title="File Still Loading"
                      style={{ cursor: "pointer" }}
                    >
                      <LockResetIcon color="yellow"></LockResetIcon>
                    </Tooltip>
                  )}
                  {row.status === "FAILED" && (
                    <Tooltip
                      title="File Export Failed"
                      style={{ cursor: "pointer" }}
                    >
                      <CloseIcon color="red"></CloseIcon>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExportDownloads;
