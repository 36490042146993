import { Grid } from "@mui/material";
import {
  AttachFileIcon,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,    
  Modal,
  ReadMoreIcon,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  DialogActions,
  DialogContent,
  Dialog,
  CloseIcon,
  IconButton,
} from "../../mui";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import UIWrapper from "../../components/UIWrapper";
import AcsEditFilter from "./AcsEditFilter";
import OnHoldSearch from "./OnHoldSearch";
import AcsService from "../../services/AcsService";
import BiService from "../../services/BiService";
import { snackbarActions } from "../../Store/snackbar";
import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import { CSVLink } from "react-csv";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AcsEditFileBulkUpload from "./AcsEditFileBulkUpload";
import DropDownCardHistory from "../storeComponents/DropDownCardHistory";
import ExportDownloads from "../exportDownloads";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const shema_type_mapping = {
  number: "integer",
  numeric: "number",
  string: "string",
  text: "string",
  decimal: "number",
  float: "number",
  double_precision: "number",
  boolean: "boolean",
};

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const tableRowData = [
  "Client",
  "Project",
  "Inventory Code",
  "Source",
  "Container Type",
  "Status",
  "Date Received / Created",
  "Ultimate Parent",
];

const AcsEdit = (props) => {
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  const [dataLoading, setDataLoading] = useState(false);
  const [value, setValue] = useState("one");
  const [searchType, setSearchType] = useState("bulk");
  const [editMode, setEditMode] = useState(false);
  const [emptyHook, setEmptyHook] = useState(0);
  const [open, setOpen] = useState(false);
  const [listLarge, setListLarge] = useState([]);
  const [largeInput, setLargeInput] = useState("");
  const [tableBulkData, setTableBulkData] = useState([]);
  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(6);
  const [val, setVal] = useState([]);
  const [filterExpand, setFilterExpand] = useState(false);
  const [inventoryCodeData, setInventoryCodeData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadData, setUploadData] = useState([]);
  const [filter, setFilter] = useState("Inv_Code");
  const [fileName, setFileName] = useState("");
  const [files, setFiles] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  //sample
  const [schema, setSchema] = useState({});
  const [schemaUI, setSchemaUI] = useState({});
  const [schemaData, setSchemaData] = useState({});
  //subject
  const [schemaSubject, setSchemaSubject] = useState({});
  const [schemaUISubject, setSchemaUISubject] = useState({});
  const [schemaDataSubject, setSchemaDataSubject] = useState({});
  //meta
  const [schemaMeta, setSchemaMeta] = useState({});
  const [schemaUIMeta, setSchemaUIMeta] = useState({});
  const [schemaDataMeta, setSchemaDataMeta] = useState({});

  //QC
  const [schemaQC, setSchemaQC] = useState({});
  const [schemaUIQC, setSchemaUIQC] = useState({});
  const [schemaDataQC, setSchemaDataQC] = useState({});

  //Services
  const [schemaServices, setSchemaServices] = useState({});
  const [schemaUIServices, setSchemaUIServices] = useState({});
  const [schemaDataServices, setSchemaDataServices] = useState({});

  //aliquot
  const [schemaAliquot, setSchemaAliquot] = useState({});
  const [schemaUIAliquot, setSchemaUIAliquot] = useState({});
  const [schemaDataAliquot, setSchemaDataAliquot] = useState({});
  const [invCodeInput, setInvCodeInput] = useState({
    inventory_code: "",
    tracking_number: "",
  });
  const [invAlert, setInvAlert] = useState(false);
  const [clientAlert, setClientAlert] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  const [containerList, setContainerList] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [dynamicHeader, setDynamicHeader] = useState({
    source: "",
    container_type: "",
    client: "",
    project: "",
    carrier: "",
    container_description: "",
  });
  const [headers, setHeaders] = useState({
    site: "",
    subject_code: "",
    date_received: "",
    time_received: "",
  });
  const [originalData, setOriginalData] = useState({
    client: "",
    project: "",
    inventory_code: "",
    dataset: "",
  });
  const [dataPopulation, setDataPopulation] = useState({});
  const [openDialog, setDialogOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [editHistory, setEditHistory] = useState([]);

  const globalTheme = useTheme();
  const theme = useMemo(() => {
    return {
      ...globalTheme,
      palette: {
        ...globalTheme.palette,
        text: {
          ...globalTheme.palette.text,
          disabled: editMode
            ? "rgba(0, 0, 0, 0.6)"
            : globalTheme.palette.secondary.main,
        },
      },
      components: {
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: globalTheme.palette.secondary.main,
            },
          },
        },
      },
    };
  }, [editMode]);

  const dispatch = useDispatch();
  const csvLink = useRef();
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const handleHeaderChange = (str, e) => {
    setDynamicHeader((prevState) => ({
      ...prevState,
      [str]: e.target.value,
    }));
  };

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    setUploadData([]);
  };
  let handleSelectOptionChange = (newVal, changeDetail) => {
    if (changeDetail == "client" || changeDetail == "project") {
      setClientAlert(true);
    }
    setDynamicHeader((prevState) => ({
      ...prevState,
      [changeDetail]: newVal,
    }));
  };
  const changeHandler = (event) => {
    setOpenUploadModal(true);
  };

  const handleUploadClose = () => {
    setOpenUploadModal(false);
  };
  const clearForm = () => {
    setSchema({});
    setSchemaUI({});
    setSchemaData({});
    setSchemaSubject({});
    setSchemaMeta({});
    setSchemaUISubject({});
    setSchemaUIMeta({});
    setSchemaDataSubject({});
    setSchemaDataMeta({});
    setSchemaAliquot({});
    setSchemaUIAliquot({});
    setSchemaDataAliquot({});
    setSchemaQC({});
    setSchemaUIQC({});
    setSchemaDataQC({});
    setSchemaServices({});
    setSchemaUIServices({});
    setSchemaDataServices({});
    setDynamicHeader({
      source: "",
      container_type: "",
      client: "",
      project: "",
      carrier: "",
      container_description: "",
    });
    setHeaders({
      site: "",
      subject_code: "",
      date_received: "",
      time_received: "",
    });
    setInvCodeInput({
      inventory_code: "",
      tracking_number: "",
    });
  };
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    clearForm();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setOpen(false);
    setEditMode(false);
    setReason("");
  };
  const checkValidation = (errors) => {
    if (errors.nativeEvent.isTrusted == true) {
      setDialogOpen(true);
    }
  };
  const submitEditData = (e) => {
    e.preventDefault();
    let submitData = {};
    {
      dataPopulation.dataset !== "Aliquot" &&
        Object.keys(invCodeInput).forEach((ele) => {
          if (
            invCodeInput[ele] ||
            invCodeInput[ele] === "" ||
            invCodeInput[ele] === undefined
          ) {
            if (invCodeInput[ele] == undefined) {
              invCodeInput[ele] = "";
            }
            submitData[ele] = invCodeInput[ele];
          }
        });
    }

    let dynamicHeaderKeys =
      dataPopulation.dataset !== "Aliquot"
        ? Object.keys(dynamicHeader)
        : Object.keys(dynamicHeader).filter((ele) => ele !== "carrier");

    dynamicHeaderKeys.forEach((ele) => {
      if (
        dynamicHeader[ele] ||
        dynamicHeader[ele] == "" ||
        dynamicHeader[ele] == undefined
      ) {
        if (dynamicHeader[ele] == undefined) {
          dynamicHeader[ele] = "";
        }
        submitData[ele] = dynamicHeader[ele];
      }
    });

    {
      dataPopulation.dataset === "Aliquot" &&
        Object.keys(schemaDataAliquot).forEach((ele) => {
          if (
            schemaDataAliquot[ele] == "" ||
            schemaDataAliquot[ele] ||
            schemaDataAliquot[ele] == undefined
          ) {
            if (schemaDataAliquot[ele] == undefined) {
              schemaDataAliquot[ele] = "";
            }
            submitData[ele] = schemaDataAliquot[ele];
          }
        });
    }

    {
      dataPopulation.dataset !== "Aliquot" &&
        Object.keys(headers).forEach((ele) => {
          if (headers[ele] && headers[ele] !== "") {
            submitData[ele] = headers[ele];
          }
        });
    }

    Object.keys(schemaDataServices).forEach((ele) => {
      if (
        schemaDataServices[ele] ||
        schemaDataServices[ele] == "" ||
        schemaDataServices[ele] == undefined
      ) {
        if (schemaDataServices[ele] == undefined) {
          schemaDataServices[ele] = "";
        }
        submitData[ele] = schemaDataServices[ele];
      }
    });

    Object.keys(schemaData).forEach((ele) => {
      if (
        schemaData[ele] ||
        schemaData[ele] == "" ||
        schemaData[ele] == undefined
      ) {
        if (schemaData[ele] == undefined) {
          schemaData[ele] = "";
        }
        submitData[ele] = schemaData[ele];
      }
    });
    Object.keys(schemaDataSubject).forEach((ele) => {
      if (
        schemaDataSubject[ele] ||
        schemaDataSubject[ele] == "" ||
        schemaDataSubject[ele] == undefined
      ) {
        if (schemaDataSubject[ele] == undefined) {
          schemaDataSubject[ele] = "";
        }
        submitData[ele] = schemaDataSubject[ele];
      }
    });
    Object.keys(schemaDataMeta).forEach((ele) => {
      if (
        schemaDataMeta[ele] ||
        schemaDataMeta[ele] == "" ||
        schemaDataMeta[ele] == undefined
      ) {
        if (schemaDataMeta[ele] == undefined) {
          schemaDataMeta[ele] = "";
        }
        submitData[ele] = schemaDataMeta[ele];
      }
    });

    let final = {
      inventory_code: originalData.inventory_code,
      // tracking_number: invCodeInput.tracking_number,
      client: originalData.client,
      project: originalData.project,
      edits: submitData,
      reason: reason,
      dataset: originalData.dataset,
    };
    AcsService.submitEditForm(final)
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
        setEmptyHook(emptyHook + 1);
        setOpen(false);
        setDialogOpen(false);
        setEditMode(false);
        setReason("");
        clearForm();
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
      });
  };
  const handleSubmission = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const data = new FormData();
      data.append("file", selectedFile);
      BiService.uploadFile(data)
        .then((resp) => {
          setUploadData(resp.data);
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Successfully Uploaded",
            })
          );
          setSelectedFile(null);
        })
        .catch((err) => {
          dispatch(
            snackbarActions.showNotification({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: err.response.data.detail,
            })
          );
          setSelectedFile(null);
        });
      return;
    }
    setDataLoading(true);
    setPageLarge(0);
    setTableBulkData([]);
    setEmptyHook(emptyHook + 1);
  };
  const handleAcsEdit = (row) => {
    setInvAlert(false);
    setClientAlert(false);
    const { client, project, inventory_code, ultimate_parent, dataset } = row;
    setOriginalData({
      client,
      project,
      inventory_code,
      dataset,
    });

    BiService.getEditHistory(client, project, inventory_code)
      .then((resp) => {
        setEditHistory(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    AcsService.getDynamicInputsEdits(client, project, dataset)
      .then((resp) => {
        let schema = {};
        let schemaSubject = {};
        let schemaMeta = {};
        let schemaQC = {};
        let schemaServices = {};
        let schemaAliquot = {};

        if (resp.data.subject) {
          const subject_keys = Object.keys(resp.data.subject.properties);
          const subject_properties = subject_keys.reduce((acc, curr) => {
            acc[curr] = {
              ...resp.data.subject.properties[curr],
              type: resp.data.subject.properties[curr].type
                ? shema_type_mapping[resp.data.subject.properties[curr].type]
                : "string",
            };
            return acc;
          }, {});
          schemaSubject = {
            ...resp.data.subject,
            properties: subject_properties,
          };
        }
        if (resp.data.sample) {
          const sample_keys = Object.keys(resp.data.sample.properties);
          const sample_properties = sample_keys.reduce((acc, curr) => {
            acc[curr] = {
              ...resp.data.sample.properties[curr],
              type: resp.data.sample.properties[curr].type
                ? shema_type_mapping[resp.data.sample.properties[curr].type]
                : "string",
            };
            return acc;
          }, {});
          schema = {
            ...resp.data.sample,
            properties: sample_properties,
          };
        }
        if (resp.data.meta) {
          const meta_keys = Object.keys(resp.data.meta.properties);
          const meta_properties = meta_keys.reduce((acc, curr) => {
            acc[curr] = {
              ...resp.data.meta.properties[curr],
              type: resp.data.meta.properties[curr].type
                ? shema_type_mapping[resp.data.meta.properties[curr].type]
                : "string",
            };
            return acc;
          }, {});
          schemaMeta = {
            ...resp.data.meta,
            properties: meta_properties,
          };
        }
        if (resp.data.quality_control) {
          const quality_control_keys = Object.keys(
            resp.data.quality_control.properties
          );
          const quality_control_properties = quality_control_keys.reduce(
            (acc, curr) => {
              acc[curr] = {
                ...resp.data.quality_control.properties[curr],
                type: resp.data.quality_control.properties[curr].type
                  ? shema_type_mapping[
                      resp.data.quality_control.properties[curr].type
                    ]
                  : "string",
              };
              return acc;
            },
            {}
          );
          schemaQC = {
            ...resp.data.quality_control,
            properties: quality_control_properties,
          };
        }
        if (resp.data.services) {
          const services_keys = Object.keys(resp.data.services.properties);
          const services_properties = services_keys.reduce((acc, curr) => {
            acc[curr] = {
              ...resp.data.services.properties[curr],
              type: resp.data.services.properties[curr].type
                ? shema_type_mapping[resp.data.services.properties[curr].type]
                : "string",
            };
            return acc;
          }, {});
          schemaServices = {
            ...resp.data.services,
            properties: services_properties,
          };
        }

        if (resp.data.aliquots) {
          const aliquots_keys = Object.keys(resp.data.aliquots.properties);
          const aliquots_properties = aliquots_keys.reduce((acc, curr) => {
            acc[curr] = {
              ...resp.data.aliquots.properties[curr],
              type: resp.data.aliquots.properties[curr].type
                ? shema_type_mapping[resp.data.aliquots.properties[curr].type]
                : "string",
            };
            return acc;
          }, {});
          schemaAliquot = {
            ...resp.data.aliquots,
            properties: aliquots_properties,
          };
        }

        let tempValues = {};
        if (schema.properties)
          Object.keys(schema.properties).forEach((ele) => {
            if (
              schema.properties[ele].type &&
              schema.properties[ele].type === "string"
            ) {
              if (
                (schema.properties[ele].format &&
                  schema.properties[ele].format === "date") ||
                schema.properties[ele].format === "time"
              ) {
                tempValues[ele] = undefined;
              } else {
                tempValues[ele] = undefined;
              }
            } else {
              tempValues[ele] = undefined;
            }
          });
        let temp = {};
        if (schemaSubject.properties)
          Object.keys(schemaSubject.properties).forEach((ele) => {
            if (
              schemaSubject.properties[ele].type &&
              schemaSubject.properties[ele].type === "string"
            ) {
              if (
                (schemaSubject.properties[ele].format &&
                  schemaSubject.properties[ele].format === "date") ||
                schemaSubject.properties[ele].format === "time"
              ) {
                temp[ele] = undefined;
              } else {
                temp[ele] = undefined;
              }
            } else {
              temp[ele] = undefined;
            }
          });
        let tempmeta = {};
        if (schemaMeta.properties)
          Object.keys(schemaMeta.properties).forEach((ele) => {
            if (
              schemaMeta.properties[ele].type &&
              schemaMeta.properties[ele].type === "string"
            ) {
              if (
                (schemaMeta.properties[ele].format &&
                  schemaMeta.properties[ele].format === "date") ||
                schemaMeta.properties[ele].format === "time"
              ) {
                tempmeta[ele] = undefined;
              } else {
                tempmeta[ele] = undefined;
              }
            } else {
              tempmeta[ele] = undefined;
            }
          });
        let tempQc = {};
        if (schemaQC.properties)
          Object.keys(schemaQC.properties).forEach((ele) => {
            if (
              schemaQC.properties[ele].type &&
              schemaQC.properties[ele].type === "string"
            ) {
              if (
                (schemaQC.properties[ele].format &&
                  schemaQC.properties[ele].format === "date") ||
                schemaQC.properties[ele].format === "time"
              ) {
                tempQc[ele] = undefined;
              } else {
                tempQc[ele] = undefined;
              }
            } else {
              tempQc[ele] = undefined;
            }
          });

        let tempServices = {};
        if (schemaServices.properties)
          Object.keys(schemaServices.properties).forEach((ele) => {
            if (
              schemaServices.properties[ele].type &&
              schemaServices.properties[ele].type === "string"
            ) {
              if (
                (schemaServices.properties[ele].format &&
                  schemaServices.properties[ele].format === "date") ||
                schemaServices.properties[ele].format === "time"
              ) {
                tempServices[ele] = undefined;
              } else {
                tempServices[ele] = undefined;
              }
            } else {
              tempServices[ele] = undefined;
            }
          });
        let tempaliquot = {};
        if (schemaAliquot.properties)
          Object.keys(schemaAliquot.properties).forEach((ele) => {
            if (
              schemaAliquot.properties[ele].type &&
              schemaAliquot.properties[ele].type === "string"
            ) {
              if (
                (schemaAliquot.properties[ele].format &&
                  schemaAliquot.properties[ele].format === "date") ||
                schemaAliquot.properties[ele].format === "time"
              ) {
                tempaliquot[ele] = undefined;
              } else {
                tempaliquot[ele] = undefined;
              }
            } else {
              tempaliquot[ele] = undefined;
            }
          });
        setSchemaAliquot(schemaAliquot);
        setSchemaDataAliquot(tempaliquot);
        
        setSchema(schema);
        setSchemaData(tempValues);
        setSchemaSubject(schemaSubject);
        setSchemaDataSubject(temp);

        setSchemaMeta(schemaMeta);
        setSchemaDataMeta(tempmeta);

        setSchemaQC(schemaQC);
        setSchemaDataQC(tempQc);

        setSchemaServices(schemaServices);
        setSchemaDataServices(tempServices);
        let schemaUI = {
          type: "VerticalLayout",
          elements: [],
        };
        let hor = {
          type: "HorizontalLayout",
          elements: [],
        };
        Object.keys(tempValues).forEach((ele, index) => {
          if (
            (index + 1) % 3 === 0 ||
            index + 1 === Object.keys(tempValues).length
          ) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            hor.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(hor));
            schemaUI.elements.push(temp);
            hor.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            hor.elements.push(obj);
          }
        });
        setSchemaUI(schemaUI);

        let schemaUISubject = {
          type: "VerticalLayout",
          elements: [],
        };
        let horSubject = {
          type: "HorizontalLayout",
          elements: [],
        };
        Object.keys(temp).forEach((ele, index) => {
          if ((index + 1) % 3 === 0 || index + 1 === Object.keys(temp).length) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horSubject.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(horSubject));
            schemaUISubject.elements.push(temp);
            horSubject.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horSubject.elements.push(obj);
          }
        });
        setSchemaUISubject(schemaUISubject);

        let schemaUIMeta = {
          type: "VerticalLayout",
          elements: [],
        };
        let horMeta = {
          type: "HorizontalLayout",
          elements: [],
        };
        Object.keys(tempmeta).forEach((ele, index) => {
          if (
            (index + 1) % 3 === 0 ||
            index + 1 === Object.keys(tempmeta).length
          ) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horMeta.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(horMeta));
            schemaUIMeta.elements.push(temp);
            horMeta.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horMeta.elements.push(obj);
          }
        });
        setSchemaUIMeta(schemaUIMeta);

        let schemaUIQC = {
          type: "VerticalLayout",
          elements: [],
        };
        let horQC = {
          type: "HorizontalLayout",
          elements: [],
        };
        Object.keys(tempQc).forEach((ele, index) => {
          if (
            (index + 1) % 3 === 0 ||
            index + 1 === Object.keys(tempQc).length
          ) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horQC.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(horQC));
            schemaUIQC.elements.push(temp);
            horQC.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horQC.elements.push(obj);
          }
        });
        setSchemaUIQC(schemaUIQC);

        let schemaUIServices = {
          type: "VerticalLayout",
          elements: [],
        };
        let horServices = {
          type: "HorizontalLayout",
          elements: [],
        };
        Object.keys(tempServices).forEach((ele, index) => {
          if (
            (index + 1) % 3 === 0 ||
            index + 1 === Object.keys(tempServices).length
          ) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horServices.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(horServices));
            schemaUIServices.elements.push(temp);
            horServices.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horServices.elements.push(obj);
          }
        });
        setSchemaUIServices(schemaUIServices);

        let schemaUIAliquot = {
          type: "VerticalLayout",
          elements: [],
        };
        let horAliquot = {
          type: "HorizontalLayout",
          elements: [],
        };
        Object.keys(tempaliquot).forEach((ele, index) => {
          if (
            (index + 1) % 3 === 0 ||
            index + 1 === Object.keys(tempaliquot).length
          ) {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horAliquot.elements.push(obj);
            let temp = JSON.parse(JSON.stringify(horAliquot));
            schemaUIAliquot.elements.push(temp);
            horAliquot.elements = [];
          } else {
            let obj = {
              type: "Control",
              scope: `#/properties/${ele}`,
            };
            horAliquot.elements.push(obj);
          }
        });
        setSchemaUIAliquot(schemaUIAliquot);

        let data = JSON.parse(
          JSON.stringify({
            client,
            project,
            inventory_code,
            ultimate_parent,
            dataset,
          })
        );
        setDataPopulation(data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
      });
  };
  const handleExport = async (event) => {
    let currentTimestamp = Date.now();
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(currentTimestamp);
    date = date.replace(/[^a-zA-Z0-9- ]/g, "_");
    date = date.replace(/\s/g, "");
    date = date.slice(0, -2);
    setFileName("sample_data_export_" + date + ".csv");
    let keyArray = tableBulkData.map(function (item) {
      return item["inventory_code"];
    });
    await BiService.exportSearch(keyArray)
      .then((resp) => {
        setInventoryCodeData(resp.data);
        // console.log("exp called");
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Sample Data Export Failed",
          })
        );
      });
  };
  const handleChangeInputs = (str, e) => {
    setHeaders((prevState) => ({
      ...prevState,
      [str]: e.target.value,
    }));
  };
  const handleChangeInvCode = (str, e) => {
    setInvAlert(true);
    setInvCodeInput((prevState) => ({
      ...prevState,
      [str]: e.target.value,
    }));
  };
  const handleExpandView = () => {
    setFilterExpand(!filterExpand);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };
  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };
  const downloadTemplate = () => {
    AcsService.downloadSSTemplate()
      .then((resp) => {
        const url = resp.data.presigned_url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "template");
        document.body.appendChild(link);
        link.click();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Successfully Downloaded Template",
          })
        );
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        );
        setFiles(null);
      });
    return;
  };
  useEffect(() => {
    // BiService.getAllClients("").then((resp) => setClientOptions(resp.data));
    BiService.getAllCarriers().then((resp) => setCarrierOptions(resp.data));
    return;
  }, [tableBulkData]);
  // useEffect(() => {
  //   BiService.searchProjects(dynamicHeader.client, dynamicHeader.project).then(
  //     (resp) => setProjectOptions(resp.data)
  //   );
  //   return;
  // }, [dynamicHeader.client, dynamicHeader.project]);
  useEffect(() => {
    if (inventoryCodeData.length !== 0) {
      csvLink.current.link.click();
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Export Succesfull.",
        })
      );
    }
  }, [inventoryCodeData]);
  useEffect(() => {
    BiService.getCodeList(largeInput)
      .then((resp) => {
        setListLarge(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [largeInput]);
  useEffect(() => {
    if (val.length === 0) setDataLoading(false);
    BiService.bulkSearch(val)
      .then((resp) => {
        setTableBulkData(resp.data);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
      });
  }, [emptyHook]);
  useEffect(() => {
    setTableBulkData(uploadData);
    setDataLoading(false);
  }, [uploadData]);
  useEffect(() => {
    AcsService.getEditData(
      dataPopulation.client,
      dataPopulation.project,
      dataPopulation.inventory_code,
      dataPopulation.dataset
    )
      .then((resp) => {
        let data = schemaData;
        let subjectData = schemaDataSubject;
        let metaData = schemaDataMeta;
        let qcData = schemaDataQC;
        let servicesData = schemaDataServices;
        let aliquotData = schemaDataAliquot;
        let header = headers;
        let headerDynamic = dynamicHeader;
        let invInputs = invCodeInput;
        if (resp.data.main) {
          let mainData = resp.data.main;
          Object.keys(resp.data.main).forEach((ele) => {
            if (ele in data && mainData[ele] !== null) {
              data[ele] = mainData[ele];
            }
            if (ele in subjectData && mainData[ele] !== null) {
              subjectData[ele] = mainData[ele];
            }
            if (ele in aliquotData && mainData[ele] !== null) {
              aliquotData[ele] = mainData[ele];
            }
            if (ele in metaData && mainData[ele] !== null) {
              metaData[ele] = mainData[ele];
            }
            if (ele in header && mainData[ele] !== null) {
              header[ele] = mainData[ele];
            }
            if (ele in headerDynamic && mainData[ele] !== null) {
              headerDynamic[ele] = mainData[ele];
            }
            if (ele in invInputs) {
              invInputs[ele] = mainData[ele];
            }
          });
        }
        if (resp.data.quality_control) {
          let qualityData = resp.data.quality_control;
          Object.keys(qualityData).forEach((ele) => {
            if (ele in qcData && qualityData[ele] !== null) {
              qcData[ele] = qualityData[ele];
            }
          });
        }

        if (resp.data.services) {
          let servData = resp.data.services;
          Object.keys(servData).forEach((ele) => {
            if (ele in servicesData && servData[ele] !== null) {
              servicesData[ele] = servData[ele];
            }
          });
        }
        setSchemaData(data);
        setSchemaDataSubject(subjectData);
        setSchemaDataMeta(metaData);
        setSchemaDataQC(qcData);
        setSchemaDataServices(servicesData);
        setHeaders(header);
        setInvCodeInput(invInputs);
        setDynamicHeader(headerDynamic);
        setSchemaDataAliquot(aliquotData);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataPopulation]);
  useEffect(() => {
    BiService.getSources()
      .then((resp) => {
        setSourceList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
    BiService.getContainers()
      .then((resp) => {
        setContainerList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setTableBulkData([]);
  }, [value]);

  return (
    <UIWrapper title="Search Samples" paper>
      {/* <Paper elevation={3} style={{ width: "95%", minHeight: "100%", marginTop: "20px", padding: "20px" }}> */}
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab value="one" label="BASIC SEARCH" />
        <Tab value="two" label="ADVANCED SEARCH" />
        <Tab value="four" label="SAMPLES ON-HOLD" />
        <Tab value="three" label="DOWNLOADS" />
      </Tabs>
      <Divider style={{ width: "100%" }}></Divider>
      {value === "two" && (
        <AcsEditFilter
          tableBulkData={tableBulkData}
          setTableBulkData={setTableBulkData}
          setValue={setValue}
        />
      )}
      {value === "four" && <OnHoldSearch />}
      {value === "three" && <ExportDownloads type="sample"></ExportDownloads>}
      {value === "one" && (
        <Box>
          <Modal
            open={openUploadModal}
            onClose={handleUploadClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Stack>
              <AcsEditFileBulkUpload
                files={selectedFile}
                setFiles={setSelectedFile}
                setOpen={setOpenUploadModal}
                downloadTemplate={downloadTemplate}
                accept={["csv", "xlsx"]}
              />
            </Stack>
          </Modal>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: 25,
              gap: 20,
            }}
          >
            <Box>
              
              <FormControl
                variant="standard"
                style={{ width: "400px", marginBottom: "auto",marginLeft: "10px" }}
              >
                <Autocomplete
                  multiple
                  id="tags-standard"
                  freeSolo
                  inputValue={largeInput}
                  onInputChange={(e, newValue) => setLargeInput(newValue)}
                  options={listLarge}
                  onChange={(e, newValue) => setVal(newValue)}
                  // getOptionLabel={option => option.title}
                  value={val}
                  renderTags={() => {}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="standard-multiline-flexible"
                      variant="standard"
                      placeholder="Search by inventory code"
                      margin="normal"
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Typography sx={{ fontWeight: 35 }}>or</Typography>
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              onClick={changeHandler}
            >
              {selectedFile ? (
                <>
                  {selectedFile.name}
                  <IconButton
                    aria-label="close"
                    onClick={handleRemoveFile}
                    sx={{
                      // right: -8,
                      // top: -8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                "Upload a File"
              )}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmission}
            >
              Search
            </Button>

            {tableBulkData.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="blue"
                  onClick={handleExport}
                >
                  Export
                </Button>
              </Box>
            )}
          </Box>
          <Stack direction="row">
            {filterExpand
              ? val.map((option, index) => (
                  <Chip
                    variant="outlined"
                    onDelete={() => {
                      setVal(val.filter((entry) => entry !== option));
                    }}
                    size="small"
                    key={index}
                    label={option}
                  />
                ))
              : val.slice(0, 5).map((option, index) => (
                  <Chip
                    variant="outlined"
                    onDelete={() => {
                      setVal(val.filter((entry) => entry !== option));
                    }}
                    size="small"
                    key={index}
                    label={option}
                  />
                ))}
            {6 <= val.length && (
              <Chip
                variant="outlined"
                size="small"
                label="Show More"
                onClick={handleExpandView}
              />
            )}
          </Stack>
        </Box>
      )}

      {value !== "three" && value !== "four" && (
        <Box>
          <Divider style={{ width: "100%", margin: "10px" }}></Divider>
          {dataLoading === false && (
            <Box>
              {searchType === "bulk" && (
                <TableContainer style={{ marginTop: "15px" }}>
                  <TablePagination
                    rowsPerPageOptions={[6, 10, 25]}
                    component="div"
                    count={tableBulkData.length}
                    rowsPerPage={rowsPerPageLarge}
                    page={pageLarge}
                    onPageChange={handleChangePageLarge}
                    onRowsPerPageChange={handleChangeRowsPerPageLarge}
                  />
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    size={"small"}
                  >
                    <TableHead sx={{ minWidth: 650 }}>
                      <TableRow sx={{ minWidth: 650 }}>
                        {tableRowData.map((row, index) => (
                          <TableCell
                            align="left"
                            key={index}
                            style={{ fontWeight: "bold" }}
                          >
                            {row}
                          </TableCell>
                        ))}
                        {/* <TableCell align="left" style={{ fontWeight: "bold" }}>
                          LIMS Transfer
                        </TableCell> */}
                        <TableCell
                          align="left"
                          style={{ fontWeight: "bold" }}
                        ></TableCell>
                        <TableCell
                          align="left"
                          style={{ fontWeight: "bold" }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: "none" }}>
                      {tableBulkData.length > 0 ? (
                        tableBulkData
                  .slice(pageLarge * rowsPerPageLarge, pageLarge * rowsPerPageLarge + rowsPerPageLarge)
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                      <TableCell align="left">{row.client || "None / Null"}</TableCell>
                      <TableCell align="left">{row.project || "None / Null"}</TableCell>
                      <TableCell align="left">{row.inventory_code || "None / Null"}</TableCell>
                      <TableCell align="left">{row.source || "None / Null"}</TableCell>
                      <TableCell align="left">{row.container_type || "None / Null"}</TableCell>
                      <TableCell align="left">{row.status || "None / Null"}</TableCell>
                      <TableCell align="left">{row.date_received_created || "None / Null"}</TableCell>
                      <TableCell align="left">{row.ultimate_parent || "None / Null"}</TableCell>
                      <TableCell align="left" onClick={() => handleAcsEdit(row)}>
                        <Tooltip title="More Detail" style={{ cursor: "pointer" }}>
                                  <ReadMoreIcon color="secondary" />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                  <TableCell align="center" colSpan={tableRowData.length + 2} >
                            No Record Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
          {dataLoading === true && (
            <Box>
              <CircularProgress
                style={{ position: "absolute", top: "55%", left: "35%" }}
                size={"11rem"}
              />
            </Box>
          )}
        </Box>
      )}
      <ThemeProvider theme={theme}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack sx={style}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              // style={{ width: "100%" }}
            >
              <Typography
                variant="h4"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  lineHeight: "116.7%",
                  letterSpacing: "-1.5px",
                }}
                color="secondary"
              >
                {editMode ? "Edit Sample Data" : "View Sample Data"}
              </Typography>
              {userProfile.user_type === "internal" && !editMode ? (
                <Button
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <Tooltip
                    title="Edit Sample Details"
                    style={{ cursor: "pointer" }}
                  >
                    <EditIcon color={"primary"}></EditIcon>
                  </Tooltip>
                </Button>
              ) : null}
            </Stack>
            <Divider style={{ width: "100%" }}></Divider>
            <ValidatorForm
              onSubmit={checkValidation}
              onError={(errors) => checkValidation(errors)}
            >
              <Stack
                style={{
                  width: "100%",
                  padding: "5px",
                  overflowY: "auto",
                  maxHeight: "75vh",
                }}
              >
                {invAlert && (
                  <Alert severity="warning">
                    Be Cautious of Changing Tracking Number!
                  </Alert>
                )}
                {clientAlert && (
                  <Alert severity="warning">
                    Be Cautious of Changing Client & Project!
                  </Alert>
                )}
                <Grid container>
                  {Object.keys(invCodeInput).map((option, index) => (
                    <Grid item xs={6} key={index}>
                      <TextValidator
                        label={
                          option.charAt(0).toUpperCase() +
                            option.slice(1).replace(/[^a-zA-Z0-9- ]/g, " ") ||
                          ""
                        }
                        size="small"
                        type="text"
                        key="index"
                        disabled={
                          option === "inventory_code" ||
                          !editMode ||
                          dataPopulation.dataset === "Aliquot"
                            ? true
                            : false
                        }
                        // required={true}
                        variant="standard"
                        style={{ width: "96%", margin: "10px 3px" }}
                        value={invCodeInput[option] || ""}
                        onChange={(e) => handleChangeInvCode(option, e)}
                        // validators={["required"]}
                        // errorMessages={["this field is required"]}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Stack direction="row" style={{ width: "100%" }}>
                  <Autocomplete
                    id="tags-standard"
                    disabled={!editMode}
                    freeSolo
                    inputValue={dynamicHeader.source || ""}
                    onInputChange={(e) => handleHeaderChange("source", e)}
                    options={sourceList}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(newValue, "source")
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          size="small"
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={!editMode}
                        // required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Source"}
                        label={"Source"}
                      />
                    )}
                  />
                  <Autocomplete
                    id="tags-standard"
                    disabled={!editMode}
                    freeSolo
                    inputValue={dynamicHeader["container_type"] || ""}
                    onInputChange={(e) =>
                      handleHeaderChange("container_type", e)
                    }
                    options={containerList}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(newValue, "container_type")
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          size="small"
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Container Type"}
                        label={"Container Type"}
                      />
                    )}
                  />
                </Stack>
                <Stack direction="row" style={{ width: "100%" }}>
                  <Autocomplete
                    disabled
                    id="tags-standard"
                    freeSolo
                    inputValue={dynamicHeader.client || ""}
                    onInputChange={(e) => handleHeaderChange("client", e)}
                    options={clientOptions}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(newValue, "client")
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          size="small"
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Client"}
                        label={"Client"}
                      />
                    )}
                  />
                  <Autocomplete
                    disabled
                    id="tags-standard"
                    freeSolo
                    inputValue={dynamicHeader["project"] || ""}
                    onInputChange={(e) => handleHeaderChange("project", e)}
                    // disabled={!dynamicHeader.client}
                    options={projectOptions}
                    onChange={(e, newValue) =>
                      handleSelectOptionChange(newValue, "project")
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string" || option instanceof String
                        ? option
                        : ""
                    }
                    style={{ width: "50%", margin: "5px" }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          size="small"
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        id="standard-multiline-flexible"
                        size="small"
                        type="text"
                        name={"Project"}
                        label={"Project"}
                      />
                    )}
                  />
                </Stack>

                <span>
                  {dataPopulation.dataset !== "Aliquot" && (
                    <Grid
                      container
                      spacing={{ xs: 1, md: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                      {Object.keys(headers).map((option, index) => (
                        <Grid
                          item
                          xs={1}
                          sm={4}
                          md={4}
                          key={index}
                          style={{ color: "#46c3cf" }}
                        >
                          {option !== "time_received" &&
                            option !== "date_received" && (
                              <TextValidator
                                label={
                                  option.charAt(0).toUpperCase() +
                                  option
                                    .slice(1)
                                    .replace(/[^a-zA-Z0-9- ]/g, " ")
                                }
                                size="small"
                                type="text"
                                disabled={!editMode}
                                variant="standard"
                                style={{ width: "80%" }}
                                value={headers[option] || ""}
                                onChange={(e) => handleChangeInputs(option, e)}
                              />
                            )}
                          {option === "date_received" && (
                            <TextValidator
                              label={
                                option.charAt(0).toUpperCase() +
                                option.slice(1).replace(/[^a-zA-Z0-9- ]/g, " ")
                              }
                              size="small"
                              type="date"
                              disabled={!editMode}
                              variant="standard"
                              style={{ width: "80%" }}
                              value={headers[option] || ""}
                              onChange={(e) => handleChangeInputs(option, e)}
                            />
                          )}
                          {option === "time_received" && (
                            <TextValidator
                              label={
                                option.charAt(0).toUpperCase() +
                                option.slice(1).replace(/[^a-zA-Z0-9- ]/g, " ")
                              }
                              size="small"
                              type="time"
                              disabled={!editMode}
                              variant="standard"
                              style={{ width: "80%" }}
                              value={headers[option] || ""}
                              onChange={(e) => handleChangeInputs(option, e)}
                            />
                          )}
                        </Grid>
                      ))}
                      <Grid
                        item
                        xs={1}
                        sm={4}
                        md={4}
                        style={{ color: "#46c3cf" }}
                      >
                        <Autocomplete
                          id="tags-standard"
                          disabled={!editMode}
                          freeSolo
                          inputValue={dynamicHeader["carrier"] || ""}
                          onInputChange={(e) =>
                            handleHeaderChange("carrier", e)
                          }
                          options={carrierOptions}
                          onChange={(e, newValue) =>
                            handleSelectOptionChange(newValue, "carrier")
                          }
                          getOptionLabel={(option) =>
                            typeof option === "string" ||
                            option instanceof String
                              ? option
                              : ""
                          }
                          style={{ width: "80%" }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                size="small"
                                key={index}
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="standard-multiline-flexible"
                              size="small"
                              type="text"
                              name={"Carrier"}
                              label={"Carrier"}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    spacing={{ xs: 1, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid
                      item
                      mt={2}
                      xs={1}
                      sm={4}
                      md={4}
                      style={{ color: "#46c3cf" }}
                    >
                      <TextField
                        id="container_desc"
                        label="Container Description"
                        disabled={!editMode}
                        variant="standard"
                        value={dynamicHeader["container_description"] || ""}
                        onChange={(e) =>
                          handleSelectOptionChange(
                            e.target.value,
                            "container_description"
                          )
                        }
                        size="small"
                        style={{ width: "80%" }}
                      />
                    </Grid>
                  </Grid>
                  <Box style={{ width: "98%" }}>
                    <Divider
                      component="div"
                      role="presentation"
                      sx={{ width: "100%", mt: 3, mb: 2 }}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ padding: "5px", color: "secondary" }}
                      >
                        Sample Inputs
                      </Typography>
                    </Divider>
                    <JsonForms
                      readonly={!editMode}
                      schema={schema}
                      uischema={schemaUI}
                      data={schemaData}
                      renderers={materialRenderers}
                      cells={materialCells}
                      onChange={({ errors, data }) => {
                        setSchemaData(data);
                      }}
                    />

                    <Divider
                      component="div"
                      role="presentation"
                      sx={{ width: "100%", mt: 3, mb: 2 }}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ padding: "5px", color: "secondary" }}
                      >
                        Subject Inputs
                      </Typography>
                    </Divider>
                    <JsonForms
                      readonly={!editMode}
                      schema={schemaSubject}
                      uischema={schemaUISubject}
                      data={schemaDataSubject}
                      renderers={materialRenderers}
                      cells={materialCells}
                      onChange={({ errors, data }) =>
                        setSchemaDataSubject(data)
                      }
                    />
                    {dataPopulation.dataset === "Aliquot" && (
                      <>
                        <Divider
                          component="div"
                          role="presentation"
                          sx={{ width: "100%", mt: 3, mb: 2 }}
                        >
                          <Typography
                            variant="h6"
                            color="primary"
                            style={{ padding: "5px", color: "secondary" }}
                          >
                            Aliquots Input
                          </Typography>
                        </Divider>
                        <JsonForms
                          readonly={!editMode}
                          schema={schemaAliquot}
                          uischema={schemaUIAliquot}
                          data={schemaDataAliquot}
                          renderers={materialRenderers}
                          cells={materialCells}
                          onChange={({ errors, data }) =>
                            setSchemaDataAliquot(data)
                          }
                        />
                      </>
                    )}
                    <Divider
                      component="div"
                      role="presentation"
                      sx={{ width: "100%", mt: 3, mb: 2 }}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ padding: "5px", color: "secondary" }}
                      >
                        Services
                      </Typography>
                    </Divider>
                    <JsonForms
                      readonly={!editMode}
                      schema={schemaServices}
                      uischema={schemaUIServices}
                      data={schemaDataServices}
                      renderers={materialRenderers}
                      cells={materialCells}
                      onChange={({ errors, data }) =>
                        setSchemaDataServices(data)
                      }
                    />
                    {!editMode && (
                      <>
                        <Divider
                          component="div"
                          role="presentation"
                          sx={{ width: "100%", mt: 3, mb: 2 }}
                        >
                          <Typography
                            variant="h6"
                            color="primary"
                            style={{ padding: "5px", color: "secondary" }}
                          >
                            Quality Control
                          </Typography>
                        </Divider>
                        <JsonForms
                          readonly
                          schema={schemaQC}
                          uischema={schemaUIQC}
                          data={schemaDataQC}
                          renderers={materialRenderers}
                          cells={materialCells}
                          onChange={({ errors, data }) => setSchemaDataQC(data)}
                        />
                      </>
                    )}

                    <Divider
                      component="div"
                      role="presentation"
                      sx={{
                        width: "100%",
                        mt: 3,
                        mb: 2,
                        // borderBottomWidth: 5,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ padding: "5px", color: "secondary" }}
                      >
                        Meta
                      </Typography>
                    </Divider>
                    <JsonForms
                      readonly={!editMode}
                      schema={schemaMeta}
                      uischema={schemaUIMeta}
                      data={schemaDataMeta}
                      renderers={materialRenderers}
                      cells={materialCells}
                      onChange={({ errors, data }) => setSchemaDataMeta(data)}
                    />
                    {editHistory.length !== 0 && !editMode && (
                      <>
                        <Divider
                          component="div"
                          role="presentation"
                          sx={{ width: "100%", mt: 3, mb: 2 }}
                        >
                          <Typography
                            variant="h6"
                            color="primary"
                            style={{ padding: "5px", color: "secondary" }}
                          >
                            Edit History
                          </Typography>
                        </Divider>

                        <DropDownCardHistory data={editHistory} />
                      </>
                    )}
                  </Box>
                </span>
                {editMode && (
                  <Button
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Submit Edits
                  </Button>
                )}
              </Stack>
            </ValidatorForm>
          </Stack>
        </Modal>
      </ThemeProvider>

      <CSVLink
        data={inventoryCodeData}
        filename={fileName}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <form noValidate>
          <DialogContent>
            <Typography variant="h6">Mention reason for your edits</Typography>
            <br />
            <TextareaAutosize
              minRows={5}
              autoFocus
              margin="dense"
              id="reason"
              label="Reason"
              type="text"
              fullWidth
              variant="standard"
              placeholder="Type reason..."
              style={{ width: 400 }}
              onChange={handleReasonChange}
              name="reason"
              value={reason}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button
              disabled={reason === "" ? true : false}
              type="submit"
              variant="contained"
              onClick={submitEditData}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* </Paper> */}
    </UIWrapper>
  );
};
export default AcsEdit;
