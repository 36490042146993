import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UIWrapper from "../../components/UIWrapper";
import ProjectManagementService from "../../services/ProjectManagementService";
import { snackbarActions } from "../../Store/snackbar";

const NewProject = () => {
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    ProjectManagementService.getFullClients("").then((resp) =>
      setClientOptions(resp.data)
    );
    return;
  }, [setClientOptions]);

  const onSubmit = () => {
    ProjectManagementService.createNewProject(
      client,
      project,
    )
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: `Successfully Added Project: ${project}`,
          })
        );
        history.push("/project-management/projects/mapping");
      })
      .catch((error) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: error.response.data.detail,
          })
        );
      });
  };

  return (
    <UIWrapper title="Create a New Project" paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Select a Client to Create a New Project. This will create a default
            project mapping. Optionally, you can choose an existing Client &
            Project to use as the template for the project mapping.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormContainer sx={{ width: "100%" }}>
            <Typography mb={0.2}>{"Select a Client & Project"}</Typography>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={3}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  margin="dense"
                  options={clientOptions}
                  onChange={(e, new_value) => setClient(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Client" />
                  )}
                  value={client}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-adornment-weight"
                  size="small"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  label="Project"
                  onChange={(event) => setProject(event.target.value)}
                  disabled={!client}
                  sx={{ width: "100%", mt: 1 }}
                  value={project}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button onClick={onSubmit} disabled={!client || !project}>
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </FormContainer>
        </Grid>
      </Grid>
    </UIWrapper>
  );
};

export default NewProject;
