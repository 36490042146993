import { useTheme } from "@mui/material/styles";
import { Box, Paper, Typography } from "../../mui";
import UIBreadcrumbs from "../UIBreadcrumbs";
import UIStepper from "./UIStepper";

const UIWrapper = (props) => {
  const theme = useTheme();
  const { title, stepper, paper, steps, activeStep, icons } = props;
  const isProjectManagementComponent = (title === "Map Manifest" || title === "New Project")? true : false;

  return (
    <Box>
      <UIBreadcrumbs />
      {stepper && !isProjectManagementComponent ? (
        <Box
          component={paper ? Paper : null}
          p={2}
          mt={2}
          sx={{ borderRadius: 2 }}
          variant={theme.palette.mode == "light" ? "elevation" : "outlined"}
        >
          <UIStepper steps={steps} activeStep={activeStep} icons={icons} />
          {paper && props.children}
        </Box>
      ) : (
        <Typography
          variant="h4"
          color={
            theme.palette.mode == "light"
              ? theme.palette.selectedNavItem.bgColor
              : "#c9d1d9"
          }
          sx={{ mt: 2, mb: 1 }}
        >
          {title}
        </Typography>
      )}
      {!stepper && paper && (
        <Box
          component={Paper}
          p={2}
          mt={2}
          sx={{
            borderRadius: 2,
            boxShadow: "0px 0px 20px -5px rgba(0,0,0,0.75)",
            width: "89vw",
            maxWidth: "89vw",
          }}
          variant={theme.palette.mode == "light" ? "elevation" : "outlined"}
        >
          {props.children}
        </Box>
      )}

      {stepper && paper && isProjectManagementComponent && (
        <Box
          component={Paper}
          p={2}
          mt={2}
          sx={{
            borderRadius: 2,
            boxShadow: "0px 0px 20px -5px rgba(0,0,0,0.75)",
            width: "98%",
            maxWidth: "98%",
          }}
          variant={theme.palette.mode == "light" ? "elevation" : "outlined"}
        >
          <UIStepper steps={steps} activeStep={activeStep} icons={icons} />
          {props.children}
        </Box>
      )}


      {!stepper && !paper && <Box>{props.children}</Box>}
    </Box>
  );
};

export default UIWrapper;
