import React from 'react';
import { Box, FormControl, TextField, Stack, Button } from "@mui/material";


export default function FormInputs(props) {

    return (
        <FormControl style={{ paddingLeft: "35px",paddingRight: "35px", paddingBottom: "15px", paddingTop: "15px",width:"85%", border: "1px solid red" }} >
            <Stack direction="column" spacing={1}>
                {
                    props.pageNum === 0 &&
                    <>
                        <TextField
                            label="Enter Project Name"
                            type="text"
                            variant="outlined"
                            value={props.formList.project}
                            onChange={(e) => props.handleChange('project', e)}
                        />
                        <TextField
                            label="Enter Plate Number"
                            type="text"
                            variant="outlined"
                            value={props.formList['pl_num']}
                            onChange={(e) => props.handleChange('pl_num', e)}
                        />
                        <TextField
                            label="Enter Batch Date"
                            type="text"
                            variant="outlined"
                            value={props.formList['batch_date']}
                            onChange={(e) => props.handleChange('batch_date', e)}
                        />

                        <Stack direction="row" style={{ padding: "0px" }} spacing={0}>
                            <TextField
                                label="Enter Manifest File Location.."
                                type="text"
                                variant="outlined"
                                style={{ width: "400px" }}
                                value={props.formList['mani_file']}
                                onChange={(e) => props.handleChange('mani_file', e)}
                            />

                            <Box >
                                <Button type="submit" variant="contained" style={{ marginLeft: "15px", marginTop: "10px" }} onClick={() => props.handleOpen('mani_file')} >Find</Button>
                            </Box>
                        </Stack>
                    </>
                }

                {
                    props.pageNum === 1 &&
                    <>
                        <Stack direction="row" spacing={0}>
                            <TextField
                                label="Enter Cell Files Location.."
                                type="text"
                                variant="outlined"
                                style={{ width: "400px" }}
                                value={props.formList['cel_dir']}
                                onChange={(e) => props.handleChange('cel_dir', e)}
                            />
                            <Box >
                                <Button type="submit" variant="contained" style={{ marginLeft: "15px", marginTop: "10px" }} onClick={() => props.handleOpen('cel_dir')} >Find</Button>
                            </Box>
                        </Stack>

                        <Stack direction="row" spacing={0}>
                            <TextField
                                label="Enter FQC Files Location.."
                                type="text"
                                variant="outlined"
                                style={{ width: "400px" }}
                                value={props.formList['fqc_file']}
                                onChange={(e) => props.handleChange('fqc_file', e)}
                            />
                            <Box >
                                <Button type="submit" variant="contained" style={{ marginLeft: "15px", marginTop: "10px" }} onClick={() => props.handleOpen('fqc_file')} >Find</Button>
                            </Box>
                        </Stack>

                        <Stack direction="row" spacing={0}>
                            <TextField
                                label="Enter Concordance Location.."
                                type="text"
                                variant="outlined"
                                style={{ width: "400px" }}
                                value={props.formList['concordance_out']}
                                onChange={(e) => props.handleChange('concordance_out', e)}
                            />
                            <Box >
                                <Button type="submit" variant="contained" style={{ marginLeft: "15px", marginTop: "10px" }} onClick={() => props.handleOpen('concordance_out')} >Find</Button>
                            </Box>
                        </Stack>

                    </>
                }

            </Stack>


        </FormControl>
    )
}
