import { styled } from "@mui/material/styles";
import { useState } from "react";
import { FileDrop } from "react-file-drop";
import { useDispatch } from "react-redux";
import { Box, Button, CloudUploadIcon, Divider, Typography } from "../../mui";
import { snackbarActions } from "../../Store/snackbar";
import AcsService from "../../services/AcsService";

const fileTypeMapping = {
  csv: "text/csv",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xml: "text/xml",
  xls: "application/vnd.ms-excel",
};

const CustomizedFileDrop = styled(FileDrop)(({ theme }) => ({
  flex: 1,
  "& .file-drop-target": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FileUploadBulkEdit = ({
  setFiles,
  accept,
  disabled,
  projectState,
  clientState,
  setValue,
  handleRefresh
}) => {
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);

  const validTypes = accept.map((key) => {
    return fileTypeMapping[key];
  });

  // After droping the file in file upload area this function will execute. 
  const handleFileDrop = (files, event) => {
    event.preventDefault();
    const filesValidation = files[0];
    if (!clientState && !projectState) {
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Select Client and Project",
        })
      );
      return;
    }
    if (!validTypes.includes(filesValidation.type)) {
      console.log(filesValidation.type);
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Invalid File Format, must be of type: ${accept.join(
            ", "
          )}`,
        })
      );
      return;
    }
    AcsService.uploadEditFileValidations(clientState, projectState, files[0])
      .then((resp) => {
        setValue("two");
        handleRefresh();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
      })
      .catch((err) => {
        setFiles(null);
      });
    setFiles(files[0]);
  };

  // After choosing & uploading the file this function will execute. 
  const handleFileUpload = (event) => {
    const filesValidation = event.target.files[0];
    if (!validTypes.includes(filesValidation.type)) {
      console.log("Invalid Type");
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Invalid File Format, must be of type: ${accept.join(
            ","
          )}`,
        })
      );
      return;
    }
    AcsService.uploadEditFileValidations(
      clientState,
      projectState,
      event.target.files[0]
    )
      .then((resp) => {
        setValue("two");
        handleRefresh();
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
      })
      .catch((err) => {
        setFiles(null);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.detail,
          })
        )
      });
    setFiles(event.target.files[0]);
    event.target.value = null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: hover ? "rgb(200,216,280,0.6)" : "rgb(216,229,242,0.5)",
        borderRadius: "5px",
      }}
    >
      <CustomizedFileDrop
        onFrameDragEnter={(event) => setHover(true)}
        onFrameDragLeave={(event) => setHover(false)}
        onFrameDrop={(event) => setHover(false)}
        onDragOver={(event) => { }}
        onDragLeave={(event) => { }}
        onDrop={(files, event) => handleFileDrop(files, event)}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloudUploadIcon
            sx={{
              mt: 2,
              fontSize: 60,
              color: "secondary.light",
            }}
          />
          <Typography sx={{ mb: 1, fontSize: 15 }}>
            Just Drag and Drop
          </Typography>
          <Divider flexItem>
            <Typography variant="h6">or</Typography>
          </Divider>
          <Button
            sx={{
              mb: 2.5,
              mt: 1,
            }}
            component="label"
            variant="contained"
            color="blue"
            disabled={disabled}
          >
            Choose a File
            <input
              type="file"
              name="file"
              accept=".xlsx"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
        </Box>
      </CustomizedFileDrop>
    </Box>
  );
};

export default FileUploadBulkEdit;
