import React, { useEffect, useState } from "react";
import { useInterval } from "../components/helper/utils";
import {
  Alert,
  Box,
  CircularProgress, Divider, Tab, Paper,
  Tabs, Typography
} from "../mui/";
import AptService from "../services/AptService";
import AptManagment from "./aptToolsComponents/AptManagment";
import PipelineForm from "./aptToolsComponents/PipelineForm";
import Progress from "./aptToolsComponents/Progress";
import UIWrapper from "../components/UIWrapper";


// const rows = [];

const AptTool = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useInterval(() => {
    AptService.getIP()
      .then((resp) => {
        console.log("You Are Connected to VPN");
        setIsLoading(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("You Are Not Connected to VPN");
        console.log(err);
      });
  }, 1000 * 7);

  useEffect(() => {
    AptService.getIP()
      .then((resp) => {
        console.log("You Are Connected to VPN");
        setIsLoading(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("You Are Not Connected to VPN");
        console.log(err);
      });
  }, []);

  return (
    <UIWrapper title="APT Tools" >
      <Box component={Paper} p={2} mt={2}>

        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab value="one" label="PIPELINE" />
          <Tab value="two" label="PROGRESS" />
          <Tab value="three" label="MANAGAMENT" />
        </Tabs>
        <Divider style={{ width: "100%" }}></Divider>
        {isLoading === false && (
          <Box sx={{marginTop: "10px"}}>
            <Alert style={{ top: "45%" }} severity="warning">
              You are currently not connected to the VPN please connect to resume
              session.
            </Alert>
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "50%" }}
              size={"3rem"}
            />
          </Box>
        )}
        <Box sx={{ width: "100%" }}>
          {isLoading === true && value === "one" && <PipelineForm></PipelineForm>}
          {isLoading === true && value === "two" && <Progress></Progress>}
          {isLoading === true && value === "three" && (
            <AptManagment></AptManagment>
          )}
        </Box>
      </Box>
    </UIWrapper>
  );
};

export default AptTool;
