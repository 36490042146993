import * as React from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import whiteLogo from "../../assets/sampled-logo-white.png";
import logo from "../../assets/sampled-logo.png";
import {
  Box,
  Divider,
  IconButton,
  MenuIcon,
  MuiAppBar,
  Toolbar,
} from "../../mui";
import ServiceList from "./ServiceList";
import ServiceSearch from "./ServiceSearch";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const drawerWidth = 200;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],

    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  }),
}));

const Header = (props) => {
  const { open, setOpen, colorMode } = props;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userProfileState = useSelector((state) => state.auth.userProfile);
  const userProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
    props.handleExpanded();
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <AppBar
      position="fixed"
      elevation={1}
      open={open}
      sx={{
        bgcolor: isLoggedIn
          ? theme.palette.mode == "light"
            ? "white"
            : "#121212"
          : "white",
      }}
    >
      {isLoggedIn ? (
        <Toolbar variant="dense">
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            color="primary"
            sx={{
              marginRight: "36px",
              padding: "0px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flex: 1 }}>
            <Link to="/home">
              <Box sx={{ height: 30, display: { xs: "none", md: "flex" } }}>
                <img
                  src={theme.palette.mode == "light" ? logo : whiteLogo}
                  alt="Logo"
                />
              </Box>
            </Link>
          </Box>
          <ServiceList />
          <Divider orientation="vertical" sx={{ ml: 1, mr: 2, height: 30 }} />
          <ServiceSearch />
          {/* <Divider orientation="vertical" sx={{ ml: 2, mr: 1, height: 30 }} /> */}
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>

          {userProfile && (
            <Stack direction="row" spacing={2}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      {userProfile?.full_name}
                    </Typography>
                    <Typography color="inherit">
                      {userProfile?.email}
                    </Typography>
                  </React.Fragment>
                }
              >
                <Button>
                  <Avatar {...stringAvatar(`${userProfile?.full_name}`)} />
                </Button>
              </HtmlTooltip>
            </Stack>
          )}
        </Toolbar>
      ) : null}

      {/* <Toolbar>
        <Box sx={{ flex: 1, height: 30, display: { xs: "none", md: "flex" } }}>
          <img
            src={theme.palette.mode == "light" ? logo : whiteLogo}
            alt="Logo"
          />
        </Box>
      </Toolbar> */}
    </AppBar>
  );
};

export default Header;
