import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProjectManagementService from "../../../services/ProjectManagementService";
import { snackbarActions } from "../../../Store/snackbar";

const SaveDesign = ({ setActiveStep }) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [clientState, setClientState] = useState("");
  const [designNameState, setDesignNameState] = useState("");
  const mapping = useSelector((state) => state.mapManifest);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccessHandler = () => {
    ProjectManagementService.newManifestProfile(
      clientState,
      designNameState,
      mapping
    )
      .then((resp) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Success",
          })
        );
        history.push("project-management/projects/mapping");
      })
      .catch((err) => {
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed Request",
          })
        );
      });
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        ProjectManagementService.getAllClients("").then((resp) =>
          setClientOptions(resp.data)
        ),
      500
    );
    return () => clearTimeout(timeOutId);
  }, []);

  return (
    <Grid container spacing={2} style={{ width: "88vw" }}>
      <Grid container item xs={4} alignItems="center">
        <Box p={2}>
          <Typography variant={"h5"}>Save Your Design</Typography>
          <Typography mt={2}>
            You can use this design later when ingesting or transforming
            manifests
          </Typography>
        </Box>
      </Grid>
      <Grid container item xs={1} justifyContent="center">
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={6}>
        <FormContainer onSuccess={onSuccessHandler}>
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pr: 2,
            }}
          > */}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                margin="dense"
                options={clientOptions}
                onChange={(e, new_value) => setClientState(new_value)}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    label="Client"
                    required
                  />
                )}
                value={clientState}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="Design"
                label="Design Name"
                size="small"
                onChange={(event) => setDesignNameState(event.target.value)}
                margin="dense"
                required
                style={{width: "100%"}}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2, textAlign: "center" }}>
            <Button type={"submit"} color={"primary"} variant="contained">
              Submit
            </Button>
          </Grid>

          {/* </Box> */}
        </FormContainer>
      </Grid>
      <Grid item xs={24}>
        <Button
          onClick={() => {
            setActiveStep((prev) => prev - 1);
          }}
        >
          &lt; Back
        </Button>
      </Grid>
    </Grid>
  );
};

export default SaveDesign;
