import { Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import config from "../../aws-exports";
import { Box, Stack } from "../../mui";
import BasicForm from "../../pages/BasicForm";
import ParentAccession from "../../pages/Parents/accession";
import ParentAdmin from "../../pages/Parents/admin";
import ParentBulletin from "../../pages/Parents/bulletin";
import ParentApt from "../../pages/Parents/apt";
import ParentHome from "../../pages/Parents/home";
import ParentSamples from "../../pages/Parents/samples";
import ParentConsole from "../../pages/Parents/managementconsole";
import ParentSampledGuides from "../../pages/Parents/sample_guide";
import ParentOnHoldTracking from "../../pages/Parents/on_hold_tracking";
import ServicesSearchPage from "../../pages/ServicesSearchPage";
import SignIn from "../../pages/SignIn";
import SnpTool from "../../pages/SnpTool";
import { refreshUser, logoutUser } from "../../Store/auth-actions";
import Drawer from "../Navigation/Drawer";
import Header from "../Navigation/Header";
import PrivateRoute from "../PrivateRoute";
import CustomSnackbar from "../Snackbar";
import { useIdleTimer } from "react-idle-timer";
import Maintenance, { Floater } from "../../pages/Maintenance";
import CommonService from "../../services/CommonService";
import JiraRequests from "../../pages/JiraRequests";
import ProjectManagement from "../../pages/Parents/project_management";
import Footer from "../Navigation/Footer";

Amplify.configure(config);

console.log(process.env.PRODUCTION);
console.log(process.env.ENV);
Auth.configure({
  storage: window.sessionStorage,
  oauth: {
    domain:
      process.env.ENV === "production"
        ? "sampledsphere.auth.us-east-1.amazoncognito.com"
        : process.env.ENV === "integration"
        ? "sampledsphere-dev.auth.us-east-1.amazoncognito.com"
        : process.env.ENV === "qas"
        ? "sampledsphere-qas.auth.us-east-1.amazoncognito.com"
        : "sampledsphere-sandbox.auth.us-east-1.amazoncognito.com",

    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    // redirectSignIn: "http://localhost:3000/home",
    // redirectSignOut: "http://localhost:3000/signin",
    redirectSignIn:
      window.location.hostname === "localhost"
        ? "http://localhost:3000/home"
        : process.env.ENV === "production"
        ? "https://sampledsphere.com/home"
        : process.env.ENV === "integration"
        ? "https://integration.sampledsphere.com/home"
        : process.env.ENV === "qas"
        ? "https://quality.sampledsphere.com/home"
        : "https://sandbox.sampledsphere.com/home",

    redirectSignOut:
      window.location.hostname === "localhost"
        ? "http://localhost:3000/signin"
        : process.env.ENV === "production"
        ? "https://sampledsphere.com/signin"
        : process.env.ENV === "integration"
        ? "https://integration.sampledsphere.com/signin"
        : process.env.ENV === "qas"
        ? "https://quality.sampledsphere.com/signin"
        : "https://sandbox.sampledsphere.com/signin",

    responseType: "code",
  },
});

const App = ({ colorMode }) => {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [historyArray, setHistoryArray] = useState([]);
  const [maintenance, setMaintenance] = useState({
    upcoming_maintenance: "false",
    maintenance_message: "",
    under_maintenance: "false",
    maintenance_env: "",
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const [tool, setTool] = useState("TOOLS");

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const handleExpanded = (view) => {
    setIsExpanded((current) => !current);
  };

  const handleViewChange = (view) => {
    setTool(view);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUser());
    return;
  }, []);

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView); // Track all subsequent pageviews
  }, [history]);

  const onIdle = () => {
    dispatch(logoutUser());
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 15,
  });
  useEffect(() => {
    getRemainingTime();
  });
  const trackPageView = () => {
    // window.mParticle.logPageView(`${window.location.pathname}`, {
    //   page: window.location.toString(),
    // });
    let temp = [];
    temp = historyArray;
    console.log(window);
    temp.push(window.location.pathname.toString());
    setHistoryArray(temp);
    console.log(window.location.toString());
    console.log(historyArray);
  };
  useEffect(() => {
    let email = new URLSearchParams(window.location.search).get("email");
    if (isLoggedIn) {
      email = JSON.parse(sessionStorage.getItem("user"))?.email?.toLowerCase();
    }
    CommonService.getMaintenanceDetails(email)
      .then((resp) => {
        setMaintenance(resp.data);
      })
      .catch((err) => console.log(err));
  }, [isLoggedIn]);

  return (
    <>
      {maintenance.maintenance_env === process.env.ENV &&
      maintenance.under_maintenance === "true" ? (
        <Maintenance />
      ) : (
        <Box
          position="relative"
          sx={{ display: "flex", minHeight: "calc(100vh - 16px)" }}
        >
          <Header
            setOpen={setOpen}
            open={open}
            tool={tool}
            handleViewChange={handleViewChange}
            handleExpanded={handleExpanded}
            colorMode={colorMode}
          />
          <Drawer
            setOpen={setOpen}
            open={open}
            tool={tool}
            handleExpanded={handleExpanded}
          />
          <Stack
            direction="column"
            sx={{ flexGrow: 1, pl: 4, pr: 4, pt: 6.6, pb: 4 }}
          >
            {maintenance.maintenance_env === process.env.ENV &&
              maintenance.upcoming_maintenance === "true" && (
                <Box component="main">
                  <Floater msg={maintenance.maintenance_message} />
                </Box>
              )}
            {/* <Box component="main" style={{border: "1px solid red"}}> */}
            <Switch>
              <PrivateRoute path="/home">
                <ParentHome historyArray={historyArray} />
              </PrivateRoute>
              <Route path="/signin" exact>
                {!isLoggedIn ? <SignIn /> : <Redirect to="/home" />}
              </Route>
              <PrivateRoute path="/apt">
                <ParentApt />
              </PrivateRoute>
              <PrivateRoute path="/sampledsphere-guides">
                <ParentSampledGuides />
              </PrivateRoute>
              <PrivateRoute path="/store">
                <ParentSamples />
              </PrivateRoute>
              <PrivateRoute path="/console">
                <ParentConsole />
              </PrivateRoute>
              <PrivateRoute path="/on-hold-tracking">
                <ParentOnHoldTracking />
              </PrivateRoute>
              <PrivateRoute path="/accession">
                <ParentAccession />
              </PrivateRoute>
              <PrivateRoute path="/project-management">
                <ProjectManagement />
              </PrivateRoute>
              <PrivateRoute exact path="/snp">
                <SnpTool />
              </PrivateRoute>
              <PrivateRoute path="/bulletin">
                <ParentBulletin />
              </PrivateRoute>
              <PrivateRoute path="/admin">
                <ParentAdmin />
              </PrivateRoute>
              <PrivateRoute path="/forms">
                <BasicForm />
              </PrivateRoute>
              <PrivateRoute exact path="/services/search">
                <ServicesSearchPage />
              </PrivateRoute>
              <PrivateRoute exact path="/jira_request">
                <JiraRequests />
              </PrivateRoute>
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
            <CustomSnackbar />
            {/* </Box> */}
            <Footer />
          </Stack>
        </Box>
      )}
    </>
  );
};

export default App;
