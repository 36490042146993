import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FormContainer } from "react-hook-form-mui";

const CoreDetails = ({
  clientOptions,
  projectOptions,
  setActiveStep,
  setProjectName,
  projectName,
  clientState,
  setClientState,
  coreMapping,
  setCoreMapping,
}) => {
  const directions = `
        This process is designed to update an existing project. 
        In this step, you'll be asked to fill out the core details for this project. Most of this process revolves around the client provided digital/physical manifest
        along with extra information that might be provided after accessioning / aliquoting.
  `;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          <Typography>{directions}</Typography>
        </Grid>
        {/* Client & Project */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Typography>{"Select a Client & Project"}</Typography>
          <FormContainer>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  margin="dense"
                  options={clientOptions}
                  onChange={(e, new_value) => setClientState(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Client" />
                  )}
                  value={clientState}
                  sx={{ minWidth: 200 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  size="small"
                  margin="dense"
                  options={projectOptions}
                  onChange={(e, new_value) => setProjectName(new_value)}
                  renderInput={(params) => (
                    <TextField margin="dense" {...params} label="Project" />
                  )}
                  value={projectName}
                  sx={{ minWidth: 200 }}
                  disabled={!clientState}
                />
              </Grid>
            </Grid>
          </FormContainer>
        </Grid>

        <Grid container item sm={0} md={1} justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item sm={12} md={6} alignItems="center" justifyContent="center">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ flex: 3 }} align="center">
                SampledSphere
              </Typography>
              <ArrowRightAltIcon
                sx={{ flex: 1, ml: 0.5, mr: 0.5, color: "white" }}
              />
              <Typography sx={{ flex: 3 }} align="center">
                Client
              </Typography>
            </Box>
            <Divider orientation="horizontal" />
            {coreMapping &&
              Object.keys(coreMapping).map((opt, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: 2,
                    pb: 2,
                  }}
                >
                  <Typography sx={{ flex: 3 }} align="center">
                    {opt}
                  </Typography>
                  <ArrowRightAltIcon sx={{ flex: 1, ml: 0.5, mr: 0.5 }} />
                  <TextField
                    id="standard-adornment-weight"
                    size="small"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    label="Client Convention"
                    onChange={(event) =>
                      setCoreMapping((init) => {
                        return { ...init, [opt]: event.target.value };
                      })
                    }
                    error={!coreMapping[opt].length}
                    sx={{ flex: 3 }}
                    value={coreMapping[opt]}
                  />
                </Box>
              ))}
          </Box>
        </Grid>
        <Grid item xs={24} container justifyContent="end">
          <Button
            onClick={() => {
              setActiveStep(1);
            }}
            disabled={
              !clientState ||
              !projectName ||
              !Object.keys(coreMapping).every((element, index) => {
                if (coreMapping[element].length) {
                  return true;
                } else {
                  return false;
                }
              })
            }
          >
            Next &gt;
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CoreDetails;
