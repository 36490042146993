import { useEffect, useState, memo } from "react";
import { Box, Paper, Typography, Stack } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import AcsService from "../../services/AcsService";

const containerColumns = [
  {
    field: "material",
    headerName: "Material",
    flex: 1,
    editable: false,
  },
  {
    field: "supplier",
    headerName: "Supplier",
    flex: 1,
    editable: false,
  },
  {
    field: "catalog",
    headerName: "Catalog",
    flex: 1,
    editable: false,
    //  headerAlign: "center",
  },
];
const sourceColumns = [
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    editable: false,
  },
  {
    field: "source_name",
    headerName: "Source Name",
    flex: 1,
    editable: false,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const ManifestHints = () => {
  const [lvcontainerRows, setLvcontainerRows] = useState([]);
  const [starLIMScontainerRows, setStarLIMScontainerRows] = useState([]);
  const [sourceRows, setSourceRows] = useState([]);

  useEffect(() => {
    AcsService.getAllStarLIMSContainerTypes()
      .then((resp) => {
        setStarLIMScontainerRows(resp.data);
      })
      .catch((err) => {
        console.log("err");
      });
    AcsService.getAllLvContainerTypes()
      .then((resp) => {
        setLvcontainerRows(resp.data);
      })
      .catch((err) => {
        console.log("err");
      });
    AcsService.getAllSources()
      .then((resp) => {
        setSourceRows(resp.data);
      })
      .catch((err) => {
        console.log("err");
      });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-around"
      alignItems="center"
      flexWrap="wrap"
      paddingBottom="10px"
      component={Paper}
      variant={"outlined"}
    >
      <Stack
        sx={{
          height: "450px",
          width: "100%",
          maxWidth: "530px",
          marginTop: "20px",
        }}
      >
        <Typography color="secondary" sx={{ mb: 1, fontSize: 18, fontWeight: "700" }}>
          StarLIMS: Valid Containers Type
        </Typography>
        <DataGrid
          rows={starLIMScontainerRows}
          columns={containerColumns}
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          height: "450px",
          width: "100%",
          maxWidth: "530px",
          marginTop: "20px",
        }}
      >
        <Typography color="secondary" sx={{ mb: 1, fontSize: 18, fontWeight: "700" }}>
          LabVantage: Valid Containers Type
        </Typography>
        <DataGrid
          rows={lvcontainerRows}
          columns={containerColumns}
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          height: "450px",
          width: "90%",
          maxWidth: "370px",
          marginTop: "20px",
        }}
      >
        <Typography color="secondary" sx={{ mb: 1, fontSize: 18, fontWeight: "700" }}>
          Valid Sources
        </Typography>
        <DataGrid
          rows={sourceRows}
          columns={sourceColumns}
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              // backgroundColor: "#97b0db",
              fontWeight: "bold",
            },
            // " & .MuiDataGrid-cell": {
            //   display: "flex",
            //   justifyContent: "center",
            // },
          }}
        />
      </Stack>
    </Box>
  );
};

export default memo(ManifestHints);
