import { Auth } from "aws-amplify";
import axios from "axios";

const ACS_API_BASE_URL = process.env.MAIN_API;

class AcsService {
  async getData(client, project, subject, tracking) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
        subject_code: `${subject}`,
        tracking_number: `${tracking}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/package",
      myInit
    );
  }

  async submitForm(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;

    return await axios.post(
      ACS_API_BASE_URL + "/accession/accession-subject-samples",
      body,
      myInit
    );
  }

  async submitEditForm(list) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = list;
    // console.log("bd", body);

    return await axios.put(ACS_API_BASE_URL + "/edit/edit-data", body, myInit);
  }

  async uploadEditFile(client, project, files) {
    console.log(client);
    console.log(project);
    console.log(files);

    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("client", client);
    formData.append("project", project);
    formData.append("file", files);

    return await axios.put(
      ACS_API_BASE_URL + "/edit/bulk-edit-data",
      formData,
      myInit
    );
  }
  async uploadBulkEditFile(client, project, filaname) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("client", client);
    formData.append("project", project);
    formData.append("file_name", filaname);

    return await axios.put(
      ACS_API_BASE_URL + "/edit/bulk-edit-data",
      formData,
      myInit
    );
  }

  async getEditData(client, project, inv, dataset) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
        inventory_code: `${inv}`,
        dataset: `${dataset}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(ACS_API_BASE_URL + "/edit/get-start-data", myInit);
  }
  async getEditDataAliquot(client, project, inv) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
        inventory_code: `${inv}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/edit/get-start-data-aliquots",
      myInit
    );
  }
  async getAllClients(clientName) {
    let myInit = {
      params: {
        query: `${clientName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/clients",
      myInit
    );
  }

  async getAllSites(clientName, projectName, filterVal) {
    let myInit = {
      params: {
        client: `${clientName}`,
        project: `${projectName}`,
        query: `${filterVal}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/sites",
      myInit
    );
  }

  async getClientProjects(clientName, project) {
    let myInit = {
      params: {
        client: `${clientName}`,
        query: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/projects",
      myInit
    );
  }

  async getSubjectCode(clientName, project, sub) {
    let myInit = {
      params: {
        client: `${clientName}`,
        project: `${project}`,
        subject_code: `${sub}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/subject-code",
      myInit
    );
  }

  async getTrackingNumber(number) {
    let myInit = {
      params: {
        tracking_number: `${number}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/tracking-number",
      myInit
    );
  }

  async getDynamicInputsEdits(clientName, project, dataset) {
    let myInit = {
      params: {
        client: `${clientName}`,
        project: `${project}`,
        dataset: `${dataset}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/edit/get-edit-dynamic-form",
      myInit
    );
  }
  async getDynamicInputs(clientName, project) {
    let myInit = {
      params: {
        client: `${clientName}`,
        project: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/get-dynamic-form",
      myInit
    );
  }

  async getDynamicInputsAliquots(clientName, project) {
    let myInit = {
      params: {
        client: `${clientName}`,
        project: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/edit/get-dynamic-form-aliquots",
      myInit
    );
  }

  async getSources() {
    let myInit = {
      // params: {
      //     file_name: `${file_name}`,
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/sources",
      myInit
    );
  }

  async getContainers(client) {
    let myInit = {
      params: {
        client,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/search/containers",
      myInit
    );
  }

  async urgentTransfer(row) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = {
      client: row.client,
      project: row.project,
      inventory_code: row.inventory_code,
    };

    return await axios.post(
      ACS_API_BASE_URL + "/accession/urgent/lims-transfer",
      body,
      myInit
    );
  }
  async downloadTemplate(client, project) {
    let myInit = {
      params: {
        client,
        project,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/edit/get-bulk-edit-template",
      myInit
    );
  }
  async downloadSSTemplate() {
    console.log("hello");
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/sample/file/inventory-code/template",
      myInit
    );
  }
  async uploadEditFileValidations(client, project, files) {
    let myInit = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("client", client);
    formData.append("project", project);
    formData.append("file", files);

    return await axios.put(
      ACS_API_BASE_URL + "/edit/bulk-edit-validation",
      formData,
      myInit
    );
  }

  async validateScannedBarcode(
    client,
    project,
    tracking_number,
    inventory_code
  ) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
        tracking_number: `${tracking_number}`,
        inventory_code: `${inventory_code}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(
      ACS_API_BASE_URL + "/accession/validate/inventory_code",
      myInit
    );
  }

  async getBulkData(scannedBarcodes) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = {
      client: scannedBarcodes.client,
      project: scannedBarcodes.project,
      tracking_number: scannedBarcodes.trackingNumber,
      inventory_codes: scannedBarcodes.inventory_code,
    };
    return await axios.post(
      ACS_API_BASE_URL + "/accession/get-subject-sample-data",
      body,
      myInit
    );
  }

  async getTrackingNumbers(client, project) {
    let myInit = {
      params: {
        client: `${client}`,
        project: `${project}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/accession/get-tracking-numbers",
      myInit
    );
  }
  async saveLimsTrackingNumbers(data) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = data;

    return await axios.post(
      ACS_API_BASE_URL + "/accession/save-lims-queue-tracking-numbers",
      body,
      myInit
    );
  }

  async getTableBarCodeData(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ACS_API_BASE_URL + "/accession/check_duplicate_manifest",
      body,
      myInit
    );
  }

  async getTableBarCodeDataAccession(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ACS_API_BASE_URL + "/accession/check_duplicate_accession",
      body,
      myInit
    );
  }

  async getTableBarCodeDataLims(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ACS_API_BASE_URL + "/accession/check_duplicate_send_to_lims",
      body,
      myInit
    );
  }

  async getAllLvContainerTypes() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/manifest/get_all_lv_container_type",
      myInit
    );
  }
  async getAllStarLIMSContainerTypes() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/manifest/get_all_container_type",
      myInit
    );
  }
  async getAllSources() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/manifest/get_all_source",
      myInit
    );
  }
  async getOnHoldSamples() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ACS_API_BASE_URL + "/sample/on-hold-samples",
      myInit
    );
  }
}

export default new AcsService();
